/* eslint-disable */
import React from 'react';
import configData from "../config/config.js";
import { Form, InputGroup, FormControl } from 'react-bootstrap';
import axios from 'axios';
import ModalComponent from '../components/ModalComponent';
//import TableComponent from './TableComponent';
import $ from 'jquery'

import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
//import { Table } from 'react-bootstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import authHeader from '../services/auth-header';
import AuthService from "../services/auth.service";
import { Alert } from 'react-bootstrap';
import Select from 'react-select';

import '../App.css';

import Datalanguages from "../languages/el.json"

export default class Users extends React.Component {

  oneItem = "user"
  manyItems = "users"
  createNewTheTitle = Datalanguages["Create new user"]
  editTheTitle = Datalanguages["Edit user"]
  deleteTheTitle = Datalanguages["Delete user"]

  user = AuthService.getCurrentUser();
  isSUPERADMIN = this.user && ["ROLE_SUPERADMIN"].some(substring => this.user.roles.includes(substring));

  hasExpand = true;
  hasDelete = true;
  hasEdit = true;
  hasCreate = true;

  state = {
    rows: [],
    getDataPath: configData.API_URL + 'get' + this.manyItems,
    createPath: configData.API_URL + 'new' + this.oneItem,
    //createPath: configData.API_URL + 'testNew' + this.oneItem,
    editPath: configData.API_URL + 'edit' + this.oneItem,
    deletePath: configData.API_URL + 'delete' + this.oneItem,
    defaultSorted: [{
      dataField: 'last_name', // if dataField is not match to any column you defined, it will be ignored.
      order: 'asc' // desc or asc
    }],
    columns: [
      {
        dataField: 'last_name',
        text: Datalanguages["last name"],
        sort: true,
        align: 'center',
        headerAlign: 'center'
      },
      {
        dataField: 'first_name',
        text: Datalanguages["name"],
        sort: true,
        align: 'center',
        headerAlign: 'center'
      },
      {
        dataField: 'username',
        text: 'username',
        sort: true,
        align: 'center',
        headerAlign: 'center'
      },
      {
        dataField: 'email',
        text: Datalanguages["email"],
        sort: true,
        align: 'center',
        headerAlign: 'center'
      },
      {
        dataField: 'jurisdiction.label',
        text: Datalanguages["jurisdiction"],
        sort: true,
        align: 'center',
        headerAlign: 'center',
        formatter: (cell, row) => {
          return (
            <>
              {row.jurisdiction.label}
              <br />
              {row.jurisdiction.value == 5 && row.producer != undefined && row.producer != null ? 'κωδικός: ' + row.producer.code : ""}
              <br />
              {row.jurisdiction.value == 5 && row.producer != undefined && row.producer != null ? 'Α.Φ.Μ.: ' + row.producer.afm : ""}
            </>
          )
        }
      },
      {
        hidden: this.isSUPERADMIN === true ? false : true,
        dataField: 'company.name',
        text: "εταιρεία",
        sort: true,
        align: 'center',
        headerAlign: 'center'
      },
    ],
    createEditModal_user_role_selected: {},
    createEditModal_user_company_selected: {},
    createEditModal_user_vineyard_selected: {},
    newPass: true,
  };

  indication() {
    return Datalanguages["Table is Empty"];
  }

  componentDidMount() {
    AuthService.checkUserIsLoggdeIn(this);
    this.getData();
  }

  getData() {
    var _this = this;
    axios.get(_this.state.getDataPath, {
      headers: authHeader(),
    })
      .then(res => {
        this.setState({
          rows: res.data.users,
          roles: res.data.roles,
          vineyards: res.data.vineyards,
          companies: res.data.companies
        });
      })
      .catch(error => {
        console.log(error);
        this.setState({
          tableError: error
        });
      })
  }

  createEditModalParams(editMode, row) {
    var _this = this;
    return {
      title: editMode ? _this.editTheTitle : _this.createNewTheTitle,
      openBtn: {
        label: editMode ? Datalanguages["EDIT"] : " + ",
        variant: editMode ? "success" : "primary",
        class: editMode ? "modalBtn" : "createBtn",
      },
      okBtn: {
        label: editMode ? Datalanguages["SAVE"] : Datalanguages["CREATE"],
        variant: editMode ? "success" : "primary"
      },
      body:
        <div>
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text>{Datalanguages["First and last name"]}</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl id="createEditModal_user_first_name" defaultValue={row && row.first_name} />
            <FormControl id="createEditModal_user_last_name" defaultValue={row && row.last_name} />
          </InputGroup>
          <Form.Group controlId="createEditModal_user_username">
            <Form.Label>{"username"}</Form.Label>
            <Form.Control type="text" defaultValue={row && row.username} />
          </Form.Group>
          {(editMode === true) && <Form.Check type="checkbox" label="νέο password"
            // checked={_this.state.newPass}
            onChange={(event) => {
              _this.state.newPass = !_this.state.newPass;
              _this.setState(_this.state);
            }}
          />}
          {_this.state.newPass && <Form.Group controlId="createEditModal_user_password" >
            {!editMode && <Form.Label>{"password"}</Form.Label>}
            <Form.Control type="text" />
          </Form.Group>}
          <Form.Group controlId="createEditModal_user_email">
            <Form.Label>{Datalanguages["email"]}</Form.Label>
            <Form.Control type="text" defaultValue={row && row.email} />
          </Form.Group>

          <div hidden={this.isSUPERADMIN === true}>
            <Form.Label>{"ρόλος χρήστη"}</Form.Label>
            <Select id="createEditModal_user_role"
              placeholder={'επιλογή...'}
              key={_this.state.createEditModal_user_role_selected.label}
              options={_this.state.roles}
              defaultValue={editMode ? _this.state.createEditModal_user_role_selected : undefined}
              onChange={(newValue) => {
                _this.createEditModal_user_role = newValue.value;
                _this.setState(_this.state);
              }}
            />
            <br />

            <div id="createEditModal_user_producer_extras" hidden={_this.createEditModal_user_role != 5}>
              <Form.Group controlId="createEditModal_user_producercode">
                <Form.Label>{"κωδικός παραγωγού"}</Form.Label>
                <Form.Control type="text" defaultValue={row && row.producer && row.producer.code} />
              </Form.Group>
              <Form.Group controlId="createEditModal_user_producerafm">
                <Form.Label>{"Α.Φ.Μ. παραγωγού"}</Form.Label>
                <Form.Control type="text" defaultValue={row && row.producer && row.producer.afm} />
              </Form.Group>
            </div>

            <div hidden={!(_this.createEditModal_user_role == 5 || _this.createEditModal_user_role == 4)}>
              <Form.Label>{"αμπελώνες"}</Form.Label>
              <Select id="createEditModal_user_vineyard"
                placeholder={'επιλογή...'}
                //isMulti = {_this.createEditModal_user_role == 4 }
                isMulti
                key={_this.state.createEditModal_user_vineyard_selected && _this.state.createEditModal_user_vineyard_selected.label + _this.createEditModal_user_role}
                options={_this.state.vineyards}
                defaultValue={editMode ? _this.state.createEditModal_user_vineyard_selected : undefined}
                onChange={(newValue) => {
                  //console.log(newValue);
                  _this.createEditModal_user_vineyards = newValue;
                  _this.setState(_this.state);
                }}
              />
            </div>
          </div>

          <div hidden={this.isSUPERADMIN !== true}>
            <Form.Label>{"εταιρεία"}</Form.Label>
            <Select id="createEditModal_user_company"
              placeholder={'επιλογή...'}
              key={_this.state.createEditModal_user_company_selected && _this.state.createEditModal_user_company_selected.label}
              options={_this.state.companies}
              defaultValue={editMode ? _this.state.createEditModal_user_company_selected : undefined}
              onChange={(newValue) => {
                _this.createEditModal_user_company = newValue.value;
                _this.setState(_this.state);
              }}
            />
            <br />
          </div>
        </div>
      ,
      handleOK: function () {
        return editMode === true ? _this.edit(row) : _this.create();
      },
      afterShow: function () {
        //console.log("afterShow");
        if (editMode === true) {
          _this.state.createEditModal_user_role_selected =
            _this.state.roles.find(el => {
              return el.value === row.jurisdiction.value;
            });
          _this.createEditModal_user_role = _this.state.createEditModal_user_role_selected.value;

          _this.state.createEditModal_user_company_selected =
            _this.state.companies.find(el => {
              return el.value === row.company_id;
            });
          _this.createEditModal_user_company = row.company_id;

          _this.state.createEditModal_user_vineyard_selected = row.vineyards_ids;
          _this.createEditModal_user_vineyards = row.vineyards_ids;
          _this.state.newPass = false;
          _this.setState(_this.state);
        }
        else {
          _this.state.newPass = true;
          _this.setState(_this.state);
          _this.createEditModal_user_role = {};
          _this.createEditModal_user_company = {};
          _this.createEditModal_user_vineyards = {};
        }
      },
    };
  }

  create() {
    if (this.createEditModal_user_vineyards && this.createEditModal_user_vineyards.length > 0) {
      this.createEditModal_user_vineyards = this.createEditModal_user_vineyards.map(el => {
        return el.value;
      });
    }
    else {
      this.createEditModal_user_vineyards = [];
    }
    var data = {
      username: $("#createEditModal_user_username").val(),
      first_name: $("#createEditModal_user_first_name").val(),
      last_name: $("#createEditModal_user_last_name").val(),
      password: $("#createEditModal_user_password").val(),
      email: $("#createEditModal_user_email").val(),
      roleId: this.createEditModal_user_role,
      setCompany: this.createEditModal_user_company,
      vineyardsIds: this.createEditModal_user_vineyards,
      code: $("#createEditModal_user_producercode").val(),
      afm: $("#createEditModal_user_producerafm").val(),
    };
    //console.log(data);
    return axios.post(
      this.state.createPath,
      data,
      { headers: authHeader() }
    )
      .then(res => {
        this.setState({
          rows: res.data.users,
          roles: res.data.roles,
          vineyards: res.data.vineyards
        });
        return res;
      })
  }

  edit(row) {
    if (this.createEditModal_user_vineyards && this.createEditModal_user_vineyards.length > 0) {
      this.createEditModal_user_vineyards = this.createEditModal_user_vineyards.map(el => {
        return el.value;
      });
    }
    else {
      this.createEditModal_user_vineyards = [];
    }
    var data = {
      id: row.id,
      username: $("#createEditModal_user_username").val(),
      first_name: $("#createEditModal_user_first_name").val(),
      last_name: $("#createEditModal_user_last_name").val(),
      password: $("#createEditModal_user_password").val(),
      email: $("#createEditModal_user_email").val(),
      roleId: this.createEditModal_user_role,
      setCompany: this.createEditModal_user_company,
      vineyardsIds: this.createEditModal_user_vineyards,
      code: $("#createEditModal_user_producercode").val(),
      afm: $("#createEditModal_user_producerafm").val(),
    };
    //console.log(data);
    return axios.put(
      this.state.editPath,
      data,
      { headers: authHeader() }
    )
      .then(res => {
        this.setState({
          rows: res.data.users,
          roles: res.data.roles,
          vineyards: res.data.vineyards
        });
        return res;
      })
  }

  deleteModalParams(row) {
    var _this = this;
    return {
      title: _this.deleteTheTitle,
      openBtn: {
        label: Datalanguages["DELETE"],
        variant: "danger",
        class: "modalBtn"
      },
      okBtn: {
        label: Datalanguages["DELETE"],
        variant: "danger"
      },
      body: Datalanguages["Are you sure you want to delete user "] + row.first_name + " " + row.last_name + " ;",
      handleOK: function () {
        //console.log("handleOK: function () {");
        return _this.delete(row.id);
      },
      afterRender: function () { },
    };
  }

  delete(ID) {
    return axios.delete(
      this.state.deletePath + "/" + ID,
      { headers: authHeader() }
    ).then(res => {
      this.setState({
        rows: res.data.users,
        roles: res.data.roles,
        vineyards: res.data.vineyards
      });
      return res;
    })
  }

  expandRow = {
    parentClassName: 'backgroundColorGrey',
    className: 'backgroundColorGrey',
    onlyOneExpanding: true,
    showExpandColumn: false,
    //expandByColumnOnly: true,
    renderer: row => (
      <div>
        {row.vineyards.length > 0 &&
          <div>
            <table style={{ textAlign: "center" }}>
              <thead>
                <tr>
                  <th colSpan="5">Αμπελώνες</th>
                </tr>
                <tr>
                  <th>κωδικός αμπελώνα</th>
                  <th>τμήμα</th>
                  <th>τοποθεσία</th>
                  <th>συντεταγμένες</th>
                  <th>εμβαδό</th>
                </tr>
              </thead>
              <tbody>
                {row.vineyards.map((el, index) => {
                  return <tr key={index}>
                    <td>{el.vineyard_code}</td>
                    <td>{el.partition}</td>
                    <td>{el.name_or_address}</td>
                    <td>{el.location}</td>
                    <td>{el.size}</td>
                  </tr>
                })}
              </tbody>
            </table>
            <br />
          </div>
        }
        {' '}
        {this.hasDelete && (this.user.id != row.id) && <ModalComponent modalParams={this.deleteModalParams(row)} />}
        {' '}
        {this.hasEdit && <ModalComponent modalParams={this.createEditModalParams(true, row)} />}
      </div>
    )
  }

  render() {
    //console.log("render");
    const { SearchBar, ClearSearchButton } = Search;
    return (<>
      {/* <div onClick={(e) => this.setState({ error: null })}></div> */}
      {this.state.tableError && <Alert key={'danger'} variant={'danger'} className="tableErrorMsg">
        {this.state.tableError}
      </Alert>}
      <ToolkitProvider
        keyField="id"
        data={this.state.rows}
        columns={this.state.columns}
        bootstrap4
        search
      >
        {
          props => (
            <div>
              <div className="pageTitle">{Datalanguages["Users"]}</div>
              {this.hasCreate && <ModalComponent modalParams={this.createEditModalParams()} />}
              {' '}
              <SearchBar
                placeholder={Datalanguages["Search"]}
                {...props.searchProps}
                className={"searchBox"}
              />
              <ClearSearchButton
                className="btn-secondary searchBoxClearBtn"
                text='X'
                {...props.searchProps}
              />
              <BootstrapTable
                defaultSorted={this.state.defaultSorted}
                key={this.state.rows}
                {...props.baseProps}
                keyField='id'
                data={this.state.rows}
                columns={this.state.columns}
                expandRow={this.hasExpand ? this.expandRow : {}}
                striped
                hover
                bordered={false}
                noDataIndication={this.indication}
                bootstrap4
                pagination={paginationFactory()}
              />
            </div>
          )
        }
      </ToolkitProvider>
    </>
    );
  }
}
