/* eslint-disable */
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Link, NavLink, Redirect, Route, Switch } from 'react-router-dom';
import Producers from '../Pages/Producers';
import Varieties from '../Pages/Varieties';
import Containers from '../Pages/Containers';
import Vineyards from '../Pages/Vineyards';
import Container_locations from '../Pages/Container_locations';
import Container_descriptions from '../Pages/Container_descriptions';
// import Stages from './Pages/Stages';
import Events_types from '../Pages/Events_types';
import Users from '../Pages/Users';
import Transports from '../Pages/Transports';
import Bottlings from '../Pages/Bottlings';
import Wines from '../Pages/Wines';
import Measurements from '../Pages/Measurements';
import Measurements_types from '../Pages/Measurements_types';
import Actions from '../Pages/Actions';
import Actions_types from '../Pages/Actions_types';
import Companies from '../Pages/Companies';
import Dashboard from '../Pages/Dashboard';

import AuthService from "../services/auth.service";

import Login from "./login.component";
// import Register from "./register.component";
import Profile from "./profile.component";


import Datalanguages from "../languages/el.json"


export default class Navigation extends React.Component {

  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    };
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();

    if (user) {
      this.setState({
        currentUser: user,

        showActionsPage: [
          "ROLE_ADMIN",
          "ROLE_OENOLOGIST",
          "ROLE_PRODUCTION_MANAGER",
          "ROLE_MECHANICAL_ENGINEER",
          "ROLE_CHEMICAL",
          "ROLE_PRODUCER",
          "ROLE_AGRONOMIST"
        ].some(substring => user.roles.includes(substring)),

        showActionsTypesPage: [
          "ROLE_ADMIN",
          "ROLE_OENOLOGIST",
          "ROLE_PRODUCTION_MANAGER",
          "ROLE_MECHANICAL_ENGINEER",
          "ROLE_CHEMICAL",
        ].some(substring => user.roles.includes(substring)),

        showTransportsPage: [
          "ROLE_ADMIN",
          "ROLE_OENOLOGIST",
          "ROLE_PRODUCTION_MANAGER",
          "ROLE_MECHANICAL_ENGINEER",
          "ROLE_CHEMICAL"
        ].some(substring => user.roles.includes(substring)),

        showBottlingsPage: [
          "ROLE_ADMIN",
          "ROLE_SALES_MANAGER",
          "ROLE_CUSTOMER_SERVICE_MANAGER",
          "ROLE_OENOLOGIST",
          "ROLE_PRODUCTION_MANAGER",
          "ROLE_MECHANICAL_ENGINEER",
          "ROLE_CHEMICAL"
        ].some(substring => user.roles.includes(substring)),

        showMeasurementsPage: [
          "ROLE_ADMIN",
          "ROLE_OENOLOGIST",
          "ROLE_PRODUCTION_MANAGER",
          "ROLE_MECHANICAL_ENGINEER",
          "ROLE_CHEMICAL",
          "ROLE_PRODUCER",
          "ROLE_AGRONOMIST"
        ].some(substring => user.roles.includes(substring)),

        showMeasurementsTypesPage: [
          "ROLE_ADMIN",
          "ROLE_OENOLOGIST",
          "ROLE_PRODUCTION_MANAGER",
          "ROLE_MECHANICAL_ENGINEER",
          "ROLE_CHEMICAL",
        ].some(substring => user.roles.includes(substring)),

        showContainersPage: [
          "ROLE_ADMIN",
          "ROLE_OENOLOGIST",
          "ROLE_PRODUCTION_MANAGER",
          "ROLE_MECHANICAL_ENGINEER",
          "ROLE_CHEMICAL"
        ].some(substring => user.roles.includes(substring)),

        showWinesPage: [
          "ROLE_ADMIN",
          "ROLE_SALES_MANAGER",
          "ROLE_CUSTOMER_SERVICE_MANAGER",
          "ROLE_OENOLOGIST",
          "ROLE_PRODUCTION_MANAGER",
          "ROLE_MECHANICAL_ENGINEER",
          "ROLE_CHEMICAL"
        ].some(substring => user.roles.includes(substring)),

        showVinewardsPage: [
          "ROLE_ADMIN",
          "ROLE_OENOLOGIST",
          "ROLE_CHEMICAL",
          "ROLE_PRODUCER",
          "ROLE_AGRONOMIST"
        ].some(substring => user.roles.includes(substring)),

        showVarietiesPage: [
          "ROLE_ADMIN",
          "ROLE_OENOLOGIST",
          "ROLE_PRODUCTION_MANAGER",
          "ROLE_MECHANICAL_ENGINEER",
          "ROLE_CHEMICAL",
          "ROLE_AGRONOMIST"
        ].some(substring => user.roles.includes(substring)),

        showProducersPage: [
          "ROLE_ADMIN",
          "ROLE_OENOLOGIST",
          "ROLE_PRODUCTION_MANAGER",
          "ROLE_MECHANICAL_ENGINEER",
          "ROLE_CHEMICAL"
        ].some(substring => user.roles.includes(substring)),

        showUsersPage: [
          "ROLE_ADMIN",
          "ROLE_SUPERADMIN"
        ].some(substring => user.roles.includes(substring)),

        showCompaniesPage: [
          "ROLE_SUPERADMIN"
        ].some(substring => user.roles.includes(substring)),

        showDashboardPage: [
          "ROLE_ADMIN",
          "ROLE_OENOLOGIST",
          "ROLE_PRODUCTION_MANAGER",
          "ROLE_MECHANICAL_ENGINEER",
          "ROLE_PRODUCER",
          "ROLE_AGRONOMIST",
          "ROLE_SALES_MANAGER",
          "ROLE_CUSTOMER_SERVICE_MANAGER",
          "ROLE_CHEMICAL"].some(substring => user.roles.includes(substring)),
      });
    }
  }

  logOut() {
    AuthService.logout();
  }

  render() {

    const {
      currentUser,
      showActionsPage,
      showActionsTypesPage,
      showTransportsPage,
      showMeasurementsPage,
      showMeasurementsTypesPage,
      showContainersPage,
      showVinewardsPage,
      showVarietiesPage,
      showUsersPage,
      showCompaniesPage,
      showBottlingsPage,
      showWinesPage,
      showDashboardPage,
    } = this.state;

    return (
      <>
        <Navbar bg="dark" variant="dark" expand="sm" >
          <Navbar.Brand as={Link} to="/">{Datalanguages["WINETY"]}</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              {showDashboardPage && (<Nav.Link as={NavLink} to="/Dashboard">{"Dashboard"}</Nav.Link >)}
              {showActionsPage && (<NavDropdown title={"Ενέργειες"} id="actions-dropdown">
                <NavDropdown.Item as={NavLink} to="/actions">{"Ενέργειες"}</NavDropdown.Item>
                {showActionsTypesPage && <NavDropdown.Item as={NavLink} to="/actions_types">{"Τύποι ενεργειών"}</NavDropdown.Item>}
              </NavDropdown>)}
              {showMeasurementsPage && (<NavDropdown title={"Μετρήσεις"} id="measurements-dropdown">
                <NavDropdown.Item as={NavLink} to="/measurements">{"Μετρήσεις"}</NavDropdown.Item>
                {showMeasurementsTypesPage && <NavDropdown.Item as={NavLink} to="/measurements_types">{"Τύποι μετρήσεων"}</NavDropdown.Item>}
              </NavDropdown>)}
              {showTransportsPage && (<Nav.Link as={NavLink} to="/Transports">{"Μεταφορές"}</Nav.Link>)}
              {showContainersPage && (<NavDropdown title={"Οινοποιείο"} id="containers-dropdown">
                <NavDropdown.Item as={NavLink} to="/containers">{Datalanguages["Containers"]}</NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/container_locations">{Datalanguages["Container_locations"]}</NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/container_descriptions">{Datalanguages["Container_descriptions"]}</NavDropdown.Item>
              </NavDropdown>)}
              {showVinewardsPage && (<NavDropdown title={Datalanguages["Vineyards"]} id="measurements-dropdown">
                <NavDropdown.Item as={NavLink} to="/vineyards">{Datalanguages["Vineyards"]}</NavDropdown.Item>
                {showVarietiesPage && <NavDropdown.Item as={NavLink} to="/varieties">{Datalanguages["Varieties"]}</NavDropdown.Item>}
              </NavDropdown>)}
              {showBottlingsPage && (<Nav.Link as={NavLink} to="/Bottlings">{"Eμφιαλώσεις"}</Nav.Link>)}
              {showWinesPage && (<Nav.Link as={NavLink} to="/Wines">{"Τελικό προϊόν"}</Nav.Link >)}
              {showUsersPage && (<Nav.Link as={NavLink} to="/Users">{Datalanguages["Users"]}</Nav.Link>)}
              {showCompaniesPage && (<Nav.Link as={NavLink} to="/Companies">{"Εταιρείες"}</Nav.Link>)}
            </Nav>
          </Navbar.Collapse>
          {currentUser ? (
            <div className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link to={"/profile"} className="nav-link">
                  {currentUser.username}
                </Link>
              </li>
              <li className="nav-item">
                <a href="/login" className="nav-link" onClick={this.logOut}>
                  Αποσύνδεση
                </a>
              </li>
            </div>
          ) : (
              <div className="navbar-nav ml-auto">
                <li className="nav-item">
                  <Link to={"/login"} className="nav-link">
                    Σύνδεση
                </Link>
                </li>

                {/* <li className="nav-item">
                  <Link to={"/register"} className="nav-link">
                    Εγγραφή
                </Link>
                </li> */}
              </div>
            )}
        </Navbar>
        <Switch>
          <Route exact path="/login" component={Login} />
          {/* <Route exact path="/register" component={Register} /> */}
          <Route exact path="/profile" component={Profile} />
          <Route exact path='/Users' component={Users} />
          <Route exact path='/Companies' component={Companies} />
          <Route exact path='/producers' component={Producers} />
          <Route exact path='/varieties' component={Varieties} />
          <Route exact path='/vineyards' component={Vineyards} />
          {/* <Route exact path='/Stages' component={Stages} /> */}
          <Route exact path='/Events_types' component={Events_types} />
          <Route exact path='/Transports' component={Transports} />
          <Route exact path='/Bottlings' component={Bottlings} />
          <Route exact path='/Wines' component={Wines} />
          <Route exact path='/Measurements' component={Measurements} />
          <Route exact path='/Measurements_types' component={Measurements_types} />
          <Route exact path='/Actions' component={Actions} />
          <Route exact path='/Actions_types' component={Actions_types} />
          <Route exact path='/containers' component={Containers} />
          <Route exact path='/container_locations' component={Container_locations} />
          <Route exact path='/container_descriptions' component={Container_descriptions} />
          <Route exact path='/Dashboard' component={Dashboard} />
          {showCompaniesPage === true && <Redirect from="/" to="/Users" />}
          {showCompaniesPage === false && <Redirect from="/" to="/Dashboard" />}
        </Switch>
      </>
    );
  }
}

