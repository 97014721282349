/* eslint-disable */
import React from 'react';
import configData from "../config/config.js";
import { isEmail } from "validator";



// export const formatDate = value => {
//     if (!value) {
//         return (
//             moment(value).format("DD-MM-YYYY HH:mm:ss")
//         );
//     }
// };

export const requiredval = value => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};

export const emailcheck = value => {
    if (!isEmail(value)) {
        return (
            <div className="alert alert-danger" role="alert">
                This is not a valid email.
            </div>
        );
    }
};

export const usernamecheck = value => {
    if (value.length < 3 || value.length > 20) {
        return (
            <div className="alert alert-danger" role="alert">
                The username must be between 3 and 20 characters.
            </div>
        );
    }
};

export const passwordcheck = value => {
    if (value.length < 6 || value.length > 40) {
        return (
            <div className="alert alert-danger" role="alert">
                The password must be between 6 and 40 characters.
            </div>
        );
    }
};

