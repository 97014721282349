import React from 'react';
import './App.css';
import Navigation from './components/Navigation';
// import configData from "../config/config.js";

function App() {
  return (
    <>
      <div className="appContainer">
        <Navigation />
      </div>
    </>
  );
}

export default App;
