/* eslint-disable */
import React from 'react';
import moment from 'moment';
import configData from "../config/config.js";
import { Form } from 'react-bootstrap';
import axios from 'axios';
import ModalComponent from '../components/ModalComponent';
import ErrorModalComponent from '../components/ErrorModalComponent';

//import TableComponent from './TableComponent';
import $ from 'jquery'

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
//import { Table } from 'react-bootstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import authHeader from '../services/auth-header';
import AuthService from "../services/auth.service";
import { Alert } from 'react-bootstrap';
import Select from 'react-select';
import DateTimePicker from 'react-datetime-picker';
import { Button } from 'react-bootstrap';

import '../App.css';

import Datalanguages from "../languages/el.json"

export default class Transports extends React.Component {

  //errorMsgRef = React.createRef();

  oneItem = "transport"
  manyItems = "transports"
  createNewTheTitle = "Δημιουργία νέας μεταφοράς"
  editTheTitle = "Επεξεργασία μεταφοράς"
  deleteTheTitle = "Διαγραφή μεταφοράς"


  user = AuthService.getCurrentUser();
  //isProduser = this.user && ["ROLE_PRODUCER"].some(substring => this.user.roles.includes(substring));
  hasExpand = true;
  hasDelete = true;
  hasEdit = true;
  hasCreate = true;



  state = {
    rows: [],
    getDataPath: configData.API_URL + 'get' + this.manyItems,
    createPath: configData.API_URL + 'new' + this.oneItem,
    //createPath: configData.API_URL + 'testNew' + this.oneItem,
    editPath: configData.API_URL + 'edit' + this.oneItem,
    deletePath: configData.API_URL + 'delete' + this.oneItem,
    defaultSorted: [{
      dataField: 'time', // if dataField is not match to any column you defined, it will be ignored.
      order: 'desc' // desc or asc
    }],
    columns: [
      // {
      //   dataField: 'type',
      //   text: "τύπος",
      //   sort: true,
      //   align: 'center',
      //   headerAlign: 'center'
      // },
      {
        dataField: 'locationFrom_title',
        text: "από",
        sort: true,
        align: 'center',
        headerAlign: 'center'
      },
      {
        dataField: 'locationTo_title',
        text: "προς",
        sort: true,
        align: 'center',
        headerAlign: 'center',
        // formatter: (cell, row) => {
        //   cell = cell === "trush" ? "απόρριψη" : cell;
        //   return (
        //     cell
        //   );
        // },
      },
      {
        dataField: 'size',
        text: "λίτρα",
        sort: true,
        align: 'center',
        headerAlign: 'center',
        formatter: (cell, row) => {
          var cellFormat = cell + " L";
          // if (row.fromVineywrd) {
          //   cellFormat += " (" + row.sizeKG + " KG)";
          // }
          return (
            <span>{cellFormat}</span>
          );
        },
      },
      {
        dataField: 'sizeKG',
        text: "κιλά",
        sort: true,
        align: 'center',
        headerAlign: 'center',
        formatter: (cell, row) => {
          var cellFormat = cell + " Kg";
          if (cell == 0) {
            cellFormat = "-";
          }
          return (
            <span>{cellFormat}</span>
          );
        },
      },
      {
        dataField: 'time',
        text: "χρόνος",
        sort: true,
        align: 'center',
        headerAlign: 'center',
        // headerFormatter: (o) => {
        //   return (
        //     <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-clock" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        //       <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm8-7A8 8 0 1 1 0 8a8 8 0 0 1 16 0z" />
        //       <path fill-rule="evenodd" d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z" />
        //     </svg>
        //   );
        // },
      }
    ],
    createEditModal_transport_time: new Date(),
    createEditModal_transport_From_data: [],
    createEditModal_transport_To_data: [],
    createEditModal_transport_wine_data: [],
    createEditModal_transport_From_selected: {},
    createEditModal_transport_To_selected: {},
    createEditModal_transport_wine_selected: {},
    displayReceivedFromVineyard: true,
    displayTransports: true,
    displayBottlings: true,
    filterStartDate: null,
    filterEndtDate: null,
  }

  getDataForNewOrEditTransports = configData.API_URL + 'getDataForNewOrEditTransports';
  createEditModal_transport_From = {};
  createEditModal_transport_To = {};
  createEditModal_transport_wine = {};



  indication() {
    return Datalanguages["Table is Empty"];
  }

  componentDidMount() {
    AuthService.checkUserIsLoggdeIn(this);
    this.getData();
  }


  getData(params) {
    var _this = this;
    axios.post(_this.state.getDataPath,
      params,
      {
        headers: authHeader(),
      })
      .then(res => {
        _this.state.rows = res.data.Transports;
        _this.setState(_this.state);
      })
      .catch(error => {
        console.log(error);
        this.setState({
          tableError: error
        });
      })
  }

  handleClickFilter = () => {
    this.setState(this.state);
    this.getData({
      startDateRangePicker: this.state.filterStartDate,
      endDateRangePicker: this.state.filterEndtDate,
      fromVineywrds: this.state.displayReceivedFromVineyard,
      transports: this.state.displayTransports,
      bottlings: this.state.displayBottlings,
    });
  };

  createEditModalParams(editMode, row, receivedFromVineyard, forBottling) {
    var _this = this;
    var titleLbl;
    var openBtnLbl;

    var style = {};
    if (editMode) {
      openBtnLbl = Datalanguages["EDIT"]
      if (row.fromVineywrd) {
        receivedFromVineyard = true;
      }
      if (row.bottling) {
        forBottling = true;
      }
      if (receivedFromVineyard) {
        titleLbl = "Επεξεργασία παραλαβής"
      }
      else if (forBottling) {
        titleLbl = "Επεξεργασία εμφιάλωσης"
      }
      else {
        titleLbl = "Επεξεργασία μεταφοράς"
      }
    }
    else {
      if (receivedFromVineyard) {
        openBtnLbl = " + Παραλαβή"
        titleLbl = "Δημιουργία νέας παραλαβής"
        style.backgroundColor = 'rgb(200 230 201 / 88%)';
        style.color = 'black';
      }
      else if (forBottling) {
        openBtnLbl = " + Εμφιάλωση"
        titleLbl = "Δημιουργία νέας εμφιάλωσης"
        style.backgroundColor = 'rgb(255 0 0 / 16%)';
        style.color = 'black';
      }
      else {
        openBtnLbl = " + Μεταφορά"
        titleLbl = "Δημιουργία νέας μεταφοράς"
        style.backgroundColor = '#fff27669';
        style.color = 'black';
      }
    }
    return {
      title: titleLbl,
      openBtn: {
        label: openBtnLbl,
        variant: editMode ? "success" : "primary",
        class: editMode ? "modalBtn" : "transportCreateBtn",
        style: style
      },
      okBtn: {
        label: editMode ? Datalanguages["SAVE"] : Datalanguages["CREATE"],
        variant: editMode ? "success" : "primary"
      },
      body:
        <div>
          <Form.Label>{receivedFromVineyard ? "Από αμπελώνα (κωδικός αμπελώνα/τμήμα παραγωγός)" : "Από δεξαμενή (παρών περιεχόμενο/χωρητικότητα)"}</Form.Label>
          <Select id="createEditModal_transport_From"
            placeholder={'επιλογή...'}
            key={_this.state.createEditModal_transport_From_selected?.label}
            options={_this.state.createEditModal_transport_From_data}
            defaultValue={editMode ? _this.state.createEditModal_transport_From_selected : undefined}
            onChange={(newValue) => {
              this.createEditModal_transport_From = newValue.value;
              if (!(editMode === true)) { $("#createEditModal_transport_size").val(this.createEditModal_transport_From.load); }
              if (newValue.label.includes("Σταφυλοδόχο") || forBottling) {
                $("#createEditModal_transport_size").prop("readonly", true);
                $("#createEditModal_transport_size").val(this.createEditModal_transport_From.load);
              }
              else {
                $("#createEditModal_transport_size").prop("readonly", false);
              }
              if (forBottling === true) { this.calculateBottles(editMode); }
            }}
          />
          <br />
          {
            !receivedFromVineyard &&
            <div>
              <Form.Label>{forBottling ? "Προς εμφιάλωση" : "Προς δεξαμενή (παρών περιεχόμενο/χωρητικότητα)"}</Form.Label>
              {!forBottling && <Select id="createEditModal_transport_To"
                placeholder={'επιλογή...'}
                key={_this.state.createEditModal_transport_To_selected.label}
                options={_this.state.createEditModal_transport_To_data}
                defaultValue={editMode ? _this.state.createEditModal_transport_To_selected : undefined}
                onChange={(newValue) => {
                  this.createEditModal_transport_To = newValue.value;
                  //na dw an exw epilegmeno krasi kai na upologisw mpokalia klp
                }}
              />}
            </div>
          }
          {
            (editMode !== true) && receivedFromVineyard &&
            <div>
              Προς σταφυλοδόχο
              < br />
              < br />
              <Form.Group controlId="createEditModal_measurement_data">
                <Form.Label>{"Σάκχαρα σταφυλομάζας (Baume)"}</Form.Label>
                <Form.Control as="textarea" rows="1" defaultValue={""} />
              </Form.Group>
            </div>
          }
          {
            forBottling &&
            <div id="createEditModal_transport_wine_extras">
              <br />
              <Form.Group controlId="createEditModal_bottling_code">
                <Form.Label>Κωδικός παρτίδας</Form.Label>
                <Form.Control type="text" defaultValue={editMode === true ? row.bottling && row.bottling.code : ""} />
              </Form.Group>
              <Form.Label>{"Τελικό προϊόν"}</Form.Label>
              <Select id="createEditModal_wine"
                placeholder={'επιλογή...'}
                key={_this.state.createEditModal_transport_wine_selected.label}
                options={_this.state.createEditModal_transport_wine_data}
                defaultValue={editMode ? _this.state.createEditModal_transport_wine_selected : undefined}
                onChange={(newValue) => {
                  this.state.createEditModal_transport_wine_selected = newValue;
                  this.createEditModal_transport_wine = newValue.value;
                  if (forBottling === true) { this.calculateBottles(editMode); }
                }}
              />
              <br />
              <Form.Group controlId="createEditModal_transport_bottles">
                <Form.Label>{"Σύνολο παραγομένων φιαλών"}</Form.Label>
                <Form.Control type="number"
                  onChange={(event) => {
                    var newValue = event.target.value
                    // if (newValue > this.bottleMax) {
                    //   newValue = this.bottleMax;
                    //   $("#createEditModal_transport_bottles").val(this.bottleMax);
                    // }
                    this.tempSize = (this.bottleSize * newValue) / 1000;
                    $("#createEditModal_transport_size").val(this.tempSize);
                  }} />
              </Form.Group>
            </div>
          }
          {
            receivedFromVineyard &&
            <Form.Group controlId="createEditModal_transport_sizeKG">
              <Form.Label>{"Ποσότητα σε κιλά"}</Form.Label>
              <Form.Control type="number" min="1" defaultValue={editMode === true ? row.sizeKG : 1} />
            </Form.Group>
          }
          <br />
          <Form.Group controlId="createEditModal_transport_size">
            <Form.Label>{"Ποσότητα σε λίτρα"}</Form.Label>
            <Form.Control type="number" min="1" defaultValue={editMode === true ? row.size : 1} />
          </Form.Group>
          <Form.Label>{"Χρόνος "}</Form.Label>
          <br />
          <DateTimePicker
            format={"dd / MM / yyyy HH:mm"}
            maxDate={new Date()}
            clearIcon={null}
            onChange={date => {
              if (new Date(date) > new Date()) { date = new Date(); }
              this.setState({ createEditModal_transport_time: date });
            }}
            value={new Date(this.state.createEditModal_transport_time)}
            clearIcon={null}
          />
        </div >
      ,
      handleOK: function () {
        return editMode === true ? _this.edit(row) : _this.create();
      },
      afterShow: function () {
        // console.log("afterShow");        
        _this.state.createEditModal_transport_From_data = [];
        _this.state.createEditModal_transport_To_data = [];
        _this.setInitialValues(editMode, row, forBottling, receivedFromVineyard);
        _this.setState(_this.state);

        return axios.post(
          _this.getDataForNewOrEditTransports,
          {
            receivedFromVineyard: receivedFromVineyard ? true : false,
            forBottling: forBottling ? true : false,
            editMode: editMode
          },
          {
            headers: authHeader(),
          })
          .then(res => {
            res.data.from = res.data.from.filter(item => item != null);
            _this.state.createEditModal_transport_From_data = res.data.from;
            res.data.to = res.data.to.filter(item => item != null);
            _this.state.createEditModal_transport_To_data = res.data.to;

            _this.state.createEditModal_transport_wine_data = res.data.wines;
            if (editMode === true) {
              _this.state.createEditModal_transport_time = moment(row.time).utcOffset(2);
              _this.state.createEditModal_transport_From_selected =
                _this.state.createEditModal_transport_From_data.find((option) => {
                  if (row.from == null) {
                    return option.value.fromVineywrd === row.fromVineywrd;
                  }
                  else {
                    return option.value.id == row.from;
                  }
                });
              _this.state.createEditModal_transport_To_selected =
                _this.state.createEditModal_transport_To_data.find((option) => {
                  return option.value == row.to;
                });
              _this.state.createEditModal_transport_wine_selected =
                _this.state.createEditModal_transport_wine_data.find((option) => {
                  return option.value == (row.bottling && row.bottling.wine_id);
                });
              if (_this.state.createEditModal_transport_wine_selected == undefined) {
                _this.state.createEditModal_transport_wine_selected = "createEditModal_wine_key";
              }
            }
            else {
              _this.state.createEditModal_transport_wine_selected = "createEditModal_wine_key";
              _this.state.createEditModal_transport_time = new Date();
              if (forBottling || receivedFromVineyard) {
                _this.state.createEditModal_transport_To_selected = _this.state.createEditModal_transport_To_data[0];
              }
              // _this.state.createEditModal_transport_From_selected = {};
              // _this.state.createEditModal_transport_To_selected = {};
            }
            _this.setInitialValues(editMode, row, forBottling, receivedFromVineyard);
            _this.setState(_this.state);
            if (forBottling === true) { _this.calculateBottles(editMode); }
          });
      },
      afterRender: function () {
        //console.log("afterRender");
      },
    };
  }

  calculateBottles(editMode) {
    var newValue = this.state.createEditModal_transport_wine_selected;
    var load = editMode === true ? $("#createEditModal_transport_size").val() : this.createEditModal_transport_From.load
    if (
      newValue
      && (typeof newValue.size === 'number')
      && (newValue.size > 0)
    ) {
      this.bottleSize = newValue.size;
      this.tempSize = (Math.floor((load * 1000) / this.bottleSize) * this.bottleSize) / 1000;
      this.bottleMax = (this.tempSize * 1000) / this.bottleSize;
      $("#createEditModal_transport_size").val(this.tempSize);
    }
    else {
      this.bottleMax = 0;
    }
    $("#createEditModal_transport_bottles").val(this.bottleMax);
    $("#createEditModal_transport_bottles").attr({
      //"max": this.bottleMax,
      "min": 1
    });
  }

  setInitialValues(editMode, row, forBottling, receivedFromVineyard) {
    $("#createEditModal_transport_size").prop("readonly", forBottling === true ? true : false);
    if (editMode === true) {
      // console.log(row);
      // if (row.locationFrom_title.includes("Σταφυλοδόχο")) {
      //   $("#createEditModal_transport_size").prop("readonly", true);
      // }
      this.createEditModal_transport_From = {
        id: row.from,
        fromVineywrd: row.fromVineywrd,
      };
      this.createEditModal_transport_To = row.to;
      this.createEditModal_transport_time = moment(row.time).utcOffset(2);
      this.createEditModal_transport_wine = (row.bottling && row.bottling.wine_id);
    }
    else {
      this.createEditModal_transport_From = {
        id: null,
        fromVineywrd: null,
      };
      this.createEditModal_transport_To = null;
      if ((forBottling || receivedFromVineyard)
        && this.state && this.state.createEditModal_transport_To_data
        && this.state.createEditModal_transport_To_data[0] != undefined) {
        this.createEditModal_transport_To = this.state.createEditModal_transport_To_data[0].value;
      }
      else {
        this.createEditModal_transport_To = null;
      }
      this.createEditModal_transport_time = new Date();
      this.createEditModal_transport_wine = null;
    }
  }

  create() {
    return axios.post(
      this.state.createPath,
      {
        measurement_data: $("#createEditModal_measurement_data").val(),
        size: $("#createEditModal_transport_size").val(),
        sizeKG: $("#createEditModal_transport_sizeKG").val(),
        bottling_code: $("#createEditModal_bottling_code").val(),
        from: this.createEditModal_transport_From.id,
        to: this.createEditModal_transport_To,
        fromVineywrd: this.createEditModal_transport_From.fromVineywrd,
        time: this.state.createEditModal_transport_time,
        wine_id: this.createEditModal_transport_wine
      },
      { headers: authHeader() }
    ).then(res => {
      this.setState({
        rows: res.data.Transports,
        Vineyards: res.data.Vineyards,
        Containers: res.data.Containers,
        allContainers: res.data.allContainers,
      });
      return res;
    })
  }

  edit(row) {
    return axios.put(
      this.state.editPath,
      {
        id: row.id,
        size: $("#createEditModal_transport_size").val(),
        sizeKG: $("#createEditModal_transport_sizeKG").val(),
        bottling_code: $("#createEditModal_bottling_code").val(),
        from: this.createEditModal_transport_From.id,
        to: this.createEditModal_transport_To,
        fromVineywrd: this.createEditModal_transport_From.fromVineywrd,
        time: this.state.createEditModal_transport_time,
        wine_id: this.createEditModal_transport_wine
      },
      { headers: authHeader() }
    ).then(res => {
      this.setState({
        rows: res.data.Transports,
        Vineyards: res.data.Vineyards,
        Containers: res.data.Containers,
        allContainers: res.data.allContainers,
      });
      return res;
    })
  }

  deleteModalParams(row) {
    var _this = this;
    return {
      title: _this.deleteTheTitle,
      openBtn: {
        label: Datalanguages["DELETE"],
        variant: "danger",
        class: "modalBtn"
      },
      okBtn: {
        label: Datalanguages["DELETE"],
        variant: "danger"
      },
      body: <><span>Θέλετε να διαγράψετε τη μεταφορά από: {row.locationFrom_title} προς {row.locationTo_title}</span>
        <br /><span>{row.time};</span></>
      ,
      handleOK: function () {
        return _this.delete(row.id);
      },
      afterRender: function () { },
    };
  }

  delete(ID) {
    return axios.delete(
      this.state.deletePath + "/" + ID,
      { headers: authHeader() }
    ).then(res => {
      this.setState({
        rows: res.data.Transports,
        Vineyards: res.data.Vineyards,
        Containers: res.data.Containers,
        allContainers: res.data.allContainers,
      });
      return res;
    })
  }

  expandRow = {
    parentClassName: 'backgroundColorGrey',
    className: 'backgroundColorGrey',
    onlyOneExpanding: true,
    showExpandColumn: false,
    //expandByColumnOnly: true,
    renderer: row => (
      <div>
        {' '}
        {this.hasDelete && <ModalComponent modalParams={this.deleteModalParams(row)} />}
        {' '}
        {this.hasEdit && <ModalComponent modalParams={this.createEditModalParams(true, row)} />}
      </div>
    )
  }

  applyFilter(event, picker) {
    this.getData({
      startDateRangePicker: this.state.filterStartDate,
      endDateRangePicker: this.state.filterEndtDate,
      fromVineywrds: this.state.displayReceivedFromVineyard,
      transports: this.state.displayTransports,
      bottlings: this.state.displayBottlings,
    });
  };

  clearFilter() {
    $(".DateRangePickerClearIcon").click();
    this.getData();
  };


  render() {
    //console.log(this.state);
    const { SearchBar, ClearSearchButton } = Search;
    const rowStyle = (row, rowIndex) => {
      const style = {};
      if (row.fromVineywrd) {
        style.backgroundColor = 'rgb(200 230 201 / 88%)';
      } else if (row.bottling) {
        style.backgroundColor = 'rgb(255 0 0 / 16%)';
      }
      else if (row.type == "trush") {
        style.backgroundColor = 'rgba(0, 0, 0, 0.05)';
      }
      else {
        style.backgroundColor = '#fff27669';
      }
      return style;
    };
    const { ExportCSVButton } = CSVExport;

    return (
      <>
        {/* <ErrorModalComponent msg={this.state.errorMsgstring} ref={this.errorMsgRef} /> */}
        {/* <div onClick={(e) => this.setState({ error: null })}></div> */}
        {this.state.tableError && <Alert key={'danger'} variant={'danger'} className="tableErrorMsg">
          {this.state.tableError}
        </Alert>}
        <ToolkitProvider
          keyField="id"
          data={this.state.rows}
          columns={this.state.columns}
          bootstrap4
          search
          exportCSV={{
            fileName: "Winety Μεταφορές.csv",
            onlyExportFiltered: true,
            exportAll: false,
          }}
        >
          {
            props => (
              <div>
                <div className="pageTitle">Μεταφορές</div>
                <ModalComponent modalParams={this.createEditModalParams(false, null, true)} />
                <ModalComponent modalParams={this.createEditModalParams()} />
                <ModalComponent modalParams={this.createEditModalParams(false, null, false, true)} />
                {' '}
                <SearchBar
                  placeholder={Datalanguages["Search"]}
                  {...props.searchProps}
                  className={"searchBox"}
                />
                <ClearSearchButton
                  className="btn-secondary searchBoxClearBtn"
                  text='X'
                  {...props.searchProps}
                />
                <div className="DateRangePickerContainer">
                  <span className="DateRangePickerTitle">φίλτρο ημερομηνίας Από:</span>
                  <DateTimePicker
                    format={"dd / MM / yyyy HH:mm"}
                    clearIcon={<span className="DateRangePickerClearIcon">X</span>}
                    onChange={date => {
                      this.state.filterStartDate = date;
                      this.setState(this.state);
                    }}
                    value={this.state.filterStartDate}
                  />
                  <span className="DateRangePickerTitle">Έως:</span>
                  <DateTimePicker
                    format={"dd / MM / yyyy HH:mm"}
                    clearIcon={<span className="DateRangePickerClearIcon">X</span>}
                    onChange={date => {
                      this.state.filterEndtDate = date;
                      this.setState(this.state);
                    }}
                    value={this.state.filterEndtDate}
                  />
                  <Button className="DateRangePickerBtn" size="sm" onClick={(e) => this.applyFilter(e)}>
                    ΟΚ
                  </Button>
                  <Button className="DateRangePickerBtn btn-secondary" size="sm" onClick={(e) => this.clearFilter(e)}>
                    ΄Ακυρο
                  </Button>
                </div>

                <br />

                <div className="displayControleContainer">
                  <Form.Check type="checkbox" label="παραλαβές"
                    checked={this.state.displayReceivedFromVineyard}
                    onChange={(event) => {
                      this.state.displayReceivedFromVineyard = !this.state.displayReceivedFromVineyard;
                      this.handleClickFilter();
                    }}
                  />
                  <Form.Check type="checkbox" label="μεταφορές"
                    checked={this.state.displayTransports}
                    onChange={(event) => {
                      this.state.displayTransports = !this.state.displayTransports;
                      this.handleClickFilter();
                    }}
                  />
                  <Form.Check type="checkbox" label="εμφιαλώσεις"
                    checked={this.state.displayBottlings}
                    onChange={(event) => {
                      this.state.displayBottlings = !this.state.displayBottlings;
                      this.handleClickFilter();
                    }}
                  />
                </div>

                <ExportCSVButton
                  className="transportCreateBtn btn btn-primary btn-sm"
                  style={{
                    color: "#fff",
                    backgroundColor: "#17a2b8"
                  }}
                  {...props.csvProps}>
                  CSV
                </ExportCSVButton>

                <BootstrapTable
                  defaultSorted={this.state.defaultSorted}
                  key={this.state.rows}
                  {...props.baseProps}
                  keyField='id'
                  data={this.state.rows}
                  columns={this.state.columns}
                  expandRow={this.expandRow}
                  striped
                  hover
                  bordered={false}
                  noDataIndication={this.indication}
                  bootstrap4
                  pagination={paginationFactory({
                    // showTotal:true,
                    sizePerPage: 15
                  })}
                  rowStyle={rowStyle}
                />
              </div>
            )
          }
        </ToolkitProvider>
      </>
    );
  }
}
