import 'ol/ol.css';
import redmarker from '../assets/map-marker-red-md.png';
// import bluemarker from '../assets/map-marker-blue-md.png';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import BingMaps from 'ol/source/BingMaps';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
// import Layer from 'ol/layer/Layer';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
// import * as olInteraction from 'ol/interaction';
// import * as olProj from 'ol/proj';
// import { createStringXY } from 'ol/coordinate';
import {
  // Fill, 
  // Stroke, 
  // Circle, 
  Style, Icon
} from 'ol/style';

// import Datalanguages from "../languages/el.json"

import "ol-geocoder/dist/ol-geocoder.css"
import Geocoder from 'ol-geocoder';
import "ol-popup/src/ol-popup.css"
import Popup from 'ol-popup';

import React from 'react';
import $ from 'jquery';


export default class MapComponent extends React.Component {

  selectedCords = this.props.location != null ? this.props.location.split(',') : null;

  componentDidMount() {
    this.forceUpdate();
  }

  componentDidUpdate() {
    var _this = this;

    $("#" + this.props.target).empty();

    // var stringifyFunc = createStringXY(4);

    const olview = new View({
      projection: "EPSG:4326",
      // 20.8522784, 39.6639818 -> ioannina
      center: this.selectedCords == null ? [20.8522784, 39.6639818] : this.selectedCords,
      zoom: 15,
    });
    const baseLayer = new TileLayer({
      // source: new OSM(),
      source: new BingMaps({
        key: 'Ai6moSZcbUo34lpB2giKZuXIL1HZkbCBJuuGd89X9J7JocFg0CU8guCaA9YvjT8q',
        imagerySet: 'AerialWithLabelsOnDemand',
      })
    });
    const map = new Map({
      target: this.props.target,
      view: olview,
      layers: [baseLayer],
    });
    const popup = new Popup();
    const geocoder = new Geocoder('nominatim', {
      provider: 'osm',
      targetType: 'text-input',
      lang: 'gr',
      placeholder: 'Αναζήτηση τοποθεσίας...',
      limit: 5,
      keepOpen: false,
      autoComplete: true,
      autoCompleteMinLength: 2,
      autoCompleteTimeout: 200,
      countrycodes: "gr",
      preventDefault: true
    });

    if (_this.props && _this.props.singleclick) {
      map.addControl(geocoder);
      map.addOverlay(popup);
    }

    const redMarker = new Icon({
      anchor: [0.5, 1],
      size: [186, 297],
      offset: [0, 0],
      scale: 0.1,
      src: redmarker
    });

    // const blueMarker = new Icon({
    //   anchor: [0.5, 1],
    //   size: [345, 569],
    //   offset: [0, 0],
    //   scale: 0.06,
    //   src: bluemarker
    // });

    var setPinOnMap = function (selectedCords, markerIcon) {
      var iconFeature = new Feature({
        geometry: new Point(selectedCords),
      });
      var iconStyle = new Style({
        image: markerIcon,
      });
      iconFeature.setStyle(iconStyle);
      var vectorLayer = new VectorLayer({
        source: new VectorSource({
          features: [iconFeature]
        }),
      });
      return vectorLayer;
    };

    if (this.selectedCords != null) {
      var initialPosition = setPinOnMap(this.selectedCords, redMarker);
      map.addLayer(initialPosition);
      $("#initialCords").val(this.selectedCords);
    }
    //var tempPisition;

    var setNewRemoveOldPosition = function (coordinate) {
      if (initialPosition !== undefined) {
        map.removeLayer(initialPosition);
      }
      var newPosition = setPinOnMap(coordinate, redMarker);
      map.addLayer(newPosition);
      initialPosition = newPosition;
    }

    // Listen when an address is chosen
    geocoder.on('addresschosen', (evt) => {
      olview.setCenter(evt.coordinate);
      $("#createEditModal_vineyard_location").val(evt.coordinate);
      setNewRemoveOldPosition(evt.coordinate);
    });

    map.on('singleclick', function (evt) {
      // console.log(_this.props);
      if (_this.props && _this.props.singleclick) {
        $("#createEditModal_vineyard_location").val(evt.coordinate);
        setNewRemoveOldPosition(evt.coordinate);
      }
    });
  }

  // updateCoordinates() {
  //   if (this.props.updateCoordinates) {
  //     //console.log(this.props)
  //     this.props.updateCoordinates.updater(
  //       this.props.vineyardId,
  //       $("#createEditModal_vineyard_location").val()
  //     );
  //   }
  // }

  render() {
    // console.log("render");
    return (
      <>
        {/* <div className="map .mapVineyars">
        </div>
        <input readOnly id="initialCords" type="text" className="searchBox" value="" onChange={(newValue) => { }}></input>
        <br/>
        Επιλεγμένες συντεταγμένες στο χάρτη: <input readOnly id="mapValue" type="text" className="searchBox" value="" onChange={(newValue) => { }}></input>
        <button type="button" className="btn btn-success btn-sm" onClick={(e) => this.updateCoordinates(e)}>Ενημέρωση συντεταγμένων</button> */}
      </>
    );
  }
}
