import axios from "axios";
import configData from "../config/config.js";

const API_AUTH_URL = configData.API_URL + "auth/";

class AuthService {
  login(username, password) {
    return axios
      .post(API_AUTH_URL + "signin", {
        username,
        password
      })
      .then(response => {
        // console.log(response);
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  checkUserIsLoggdeIn(element) {
    //console.log(window.location);
    if (!this.getCurrentUser() && window.pathname !== "/login") {
      element.props.history.push("/login");
      window.location.reload();
      return false;
    }
    return true;
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));
  }


  // register(username, email, password, first_name, last_name, role, producer_code, producer_afm) {
  //   return axios.post(API_AUTH_URL + "signup", {
  //     username,
  //     email,
  //     password,
  //     first_name,
  //     last_name,
  //     role,
  //     producer_code,
  //     producer_afm,
  //   });
  // }
}

export default new AuthService();
