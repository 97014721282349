/* eslint-disable */
import React from 'react';
import configData from "../config/config.js";
import { Form } from 'react-bootstrap';
import axios from 'axios';
import ModalComponent from '../components/ModalComponent';
import moment from 'moment';
//import TableComponent from './TableComponent';
import $ from 'jquery'

import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
//import { Table } from 'react-bootstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import authHeader from '../services/auth-header';
import AuthService from "../services/auth.service";
import { Alert } from 'react-bootstrap';
import Select from 'react-select';
import DateTimePicker from 'react-datetime-picker';
import { Button } from 'react-bootstrap';

import '../App.css';

import Datalanguages from "../languages/el.json"

export default class Actions extends React.Component {
  oneItem = "action";
  manyItems = "actions";
  createNewTheTitle = "Εγγραφή νέας ενέργειας";
  editTheTitle = "Επεξεργασία ενέργειας";
  deleteTheTitle = "Διαγραφή ενέργειας";

  user = AuthService.getCurrentUser();
  
  isAgronomistORproducer = this.user && ["ROLE_PRODUCER", "ROLE_AGRONOMIST"].some(substring => this.user.roles.includes(substring));
  isproducer = this.user && ["ROLE_PRODUCER"].some(substring => this.user.roles.includes(substring));
  hasExpand = true;
  hasDelete = true;
  hasEdit = true;
  hasCreate = true;

  state = {
    rows: [],
    getDataPath: configData.API_URL + 'get' + this.manyItems,
    createPath: configData.API_URL + 'new' + this.oneItem,
    //createPath: configData.API_URL + 'testNew' + this.oneItem,
    editPath: configData.API_URL + 'edit' + this.oneItem,
    deletePath: configData.API_URL + 'delete' + this.manyItems,
    defaultSorted: [{
      dataField: 'datetime_created', // if dataField is not match to any column you defined, it will be ignored.
      order: 'desc' // desc or asc
    }],
    columns: [
      {
        dataField: 'source',
        text: "ενέργεια σε",
        sort: true,
        align: 'center',
        headerAlign: 'center'
      },
      {
        dataField: 'actions_type',
        text: "τύπος ενέργειας",
        sort: true,
        align: 'center',
        headerAlign: 'center',
        formatter: (cell, row) => {
          if (row.is_stage == 1) {
            return (
              <div className="backgroundColorLightBlue">αλλαγή σταδίου σε: {cell}</div>
            );
          }
          else {
            return (
              cell
            );
          }
        }
      },
      {
        dataField: 'data',
        text: "δεδομένα/παρατηρήσεις",
        sort: true,
        align: 'center',
        headerAlign: 'center',
      },
      {
        hidden: !this.isAgronomistORproducer === true ? false : true,
        dataField: 'stage',
        text: "στάδιο",
        sort: true,
        align: 'center',
        headerAlign: 'center'
      },
      {
        dataField: 'datetime_created',
        text: "χρόνος",
        sort: true,
        align: 'center',
        headerAlign: 'center',
        // headerFormatter: (o) => {
        //   return (
        //     <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-clock" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        //       <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm8-7A8 8 0 1 1 0 8a8 8 0 0 1 16 0z" />
        //       <path fill-rule="evenodd" d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z" />
        //     </svg>
        //   );
        // },
      }
    ],
    createEditModal_action_time: new Date(),
    createEditModal_action_From_data: [],
    createEditModal_action_types_data: [],
    createEditModal_action_From_selected: {},
    createEditModal_action_type_selected: {},
    filterStartDate: null,
    filterEndtDate: null,
  };

  getDataForEditactions = configData.API_URL + 'getDataForNewOrEditActions';
  getDataForNewactions = configData.API_URL + 'getDataForNewOrEditActions';
  createEditModal_action_Fromval = {};
  createEditModal_action_type = {};



  indication() {
    return Datalanguages["Table is Empty"];
  }

  componentDidMount() {
    AuthService.checkUserIsLoggdeIn(this);
    this.getData();
  }

  getData(params) {
    var _this = this;
    axios.post(_this.state.getDataPath,
      params,
      {
        headers: authHeader(),
      })
      .then(res => {
        this.setState({
          rows: res.data
        });
      })
      .catch(error => {
        console.log(error);
        this.setState({
          tableError: error
        });
      })
  }

  createEditModalParams(editMode, row) {
    var _this = this;
    return {
      title: editMode ? _this.editTheTitle : _this.createNewTheTitle,
      openBtn: {
        label: editMode ? Datalanguages["EDIT"] : " + ",
        variant: editMode ? "success" : "primary",
        class: editMode ? "modalBtn" : "createBtn",
      },
      okBtn: {
        label: editMode ? Datalanguages["SAVE"] : Datalanguages["CREATE"],
        variant: editMode ? "success" : "primary"
      },
      body:
        <div>
          <Form.Label>{"Ενέργεια σε"}</Form.Label>
          <Select id="createEditModal_action_From"
            placeholder={'επιλογή...'}
            key={_this.state.createEditModal_action_From_selected && _this.state.createEditModal_action_From_selected.label}
            options={_this.state.createEditModal_action_From_data}
            defaultValue={editMode ? _this.state.createEditModal_action_From_selected : undefined}
            onChange={(newValue) => {
              _this.createEditModal_action_Fromval = newValue.value;
              _this.state.createEditModal_action_types_data_filtered = _this.state.createEditModal_action_types_data.filter(el => {
                return el.value.source_type_id == _this.createEditModal_action_Fromval.source_type_id;
              });
              _this.forceRerender = true;
              _this.setState(_this.state);
            }}
          />
          <br />
          <Form.Label>{"Τύπος ενέργειας"}</Form.Label>
          <Select id="createEditModal_action_type"
            placeholder={'επιλογή...'}
            key={_this.state.createEditModal_action_type_selected && _this.state.createEditModal_action_type_selected.label}
            options={_this.state.createEditModal_action_types_data_filtered}
            defaultValue={editMode ? _this.state.createEditModal_action_type_selected : undefined}
            onChange={(newValue) => {
              _this.createEditModal_action_type = newValue.value;
            }}
          />
          <br />
          <div>
            <Form.Group controlId="createEditModal_action_data">
              <Form.Label>{"Δεδομένα/παρατηρήσεις"}</Form.Label>
              <Form.Control as="textarea" rows="2" defaultValue={editMode === true ? row.data : ""} />
            </Form.Group>
          </div>
          <Form.Label>{"Χρόνος"}</Form.Label>
          <br />
          <DateTimePicker
            format={"dd / MM / yyyy HH:mm"}
            clearIcon={null}
            onChange={date => {
              _this.forceRerender = true;
              this.setState({ createEditModal_action_time: date });
            }}
            value={new Date(this.state.createEditModal_action_time)}
          />
        </div>
      ,
      handleOK: function () {
        return editMode === true ? _this.edit(row) : _this.create();
      },
      afterShow: function () {
        _this.forceRerender = false;
        var getDataUrl = editMode === true ? _this.getDataForEditactions : _this.getDataForNewactions;
        return axios.get(getDataUrl, { headers: authHeader() })
          .then(res => {
            _this.state.createEditModal_action_From_data = res.data.source;
            _this.state.createEditModal_action_types_data = res.data.action_types;
            //console.log(_this.state.createEditModal_action_types_data);
            _this.state.createEditModal_action_types_data_filtered = JSON.parse(JSON.stringify(res.data.action_types));
            if (editMode === true) {
              _this.state.createEditModal_action_time = moment(row.datetime_created).utcOffset(2);
              _this.state.createEditModal_action_From_selected =
                _this.state.createEditModal_action_From_data.find((option) => {
                  return option.value.source_id === row.source_id &&
                    option.value.source_type_id === row.source_type_id;
                });
              _this.state.createEditModal_action_type_selected =
                _this.state.createEditModal_action_types_data.find((option) => {
                  return option.value.id == row.actions_type_id;
                });
            }
            else {
              _this.state.createEditModal_action_time = new Date();
            }
            _this.setState(_this.state);
          });
      },
      afterRender: function () {
        if (editMode === true && !(_this.forceRerender === true)) {
          _this.setInitialValues(row);
        }
      },
    };
  }

  setInitialValues(row) {
    //console.log(row);
    this.createEditModal_action_Fromval = {
      source_id: row.source_id,
      source_type_id: row.source_type_id,
    };
    this.createEditModal_action_type = row.actions_type_id;
    this.createEditModal_transport_time = row.time;
  }

  create() {
    return axios.post(
      this.state.createPath,
      {
        source_id: this.createEditModal_action_Fromval.source_id,
        source_type_id: this.createEditModal_action_Fromval.source_type_id,
        actions_type_id: this.createEditModal_action_type.id,
        datetime_created: this.state.createEditModal_action_time,
        data: $("#createEditModal_action_data").val(),
      },
      { headers: authHeader() }
    ).then(res => {
      // console.log(res);
      if (res.status === 200) {
        this.setState({
          rows: res.data,
        });
      }
      return res;
    });
  }

  edit(row) {
    // console.log(this.createEditModal_action_Fromval);
    return axios.put(
      this.state.editPath,
      {
        id: row.id,
        source_id: this.createEditModal_action_Fromval.source_id,
        source_type_id: this.createEditModal_action_Fromval.source_type_id,
        actions_type_id: this.createEditModal_action_type.id,
        datetime_created: this.state.createEditModal_action_time,
        data: $("#createEditModal_action_data").val(),
      },
      { headers: authHeader() }
    ).then(res => {
      // console.log(res);
      if (res.status === 200) {
        this.setState({
          rows: res.data,
        });
      }
      return res;
    });
  }

  deleteModalParams(row) {
    var _this = this;
    // console.log(row);
    return {
      title: _this.deleteTheTitle,
      openBtn: {
        label: Datalanguages["DELETE"],
        variant: "danger",
        class: "modalBtn"
      },
      okBtn: {
        label: Datalanguages["DELETE"],
        variant: "danger"
      },
      body: "Θέλετε να διαγράψετε την ενέργεια: " + row.actions_type + " σε " + row.source + ";",
      handleOK: function () {
        return _this.delete(row.id);
      },
      afterRender: function () { },
    };
  }

  delete(ID) {
    return axios.delete(
      this.state.deletePath + "/" + ID,
      { headers: authHeader() }
    ).then(res => {
      // console.log(res);
      if (res.status === 200) {
        this.setState({
          rows: res.data,
        });
      }
      return res;
    });
  }

  expandRow = {
    parentClassName: 'backgroundColorGrey',
    className: 'backgroundColorGrey',
    onlyOneExpanding: true,
    showExpandColumn: false,
    //expandByColumnOnly: true,
    renderer: row => (
      ((this.user.id == row.userId) || (!this.isproducer)) && <div>

        {' '}
        {this.hasDelete && <ModalComponent modalParams={this.deleteModalParams(row)} />}
        {' '}
        {this.hasEdit && <ModalComponent modalParams={this.createEditModalParams(true, row)} />}
      </div>
    )
  }


  applyFilter(event, picker) {
    this.getData({
      startDateRangePicker: this.state.filterStartDate,
      endDateRangePicker: this.state.filterEndtDate,
    });
  };

  clearFilter() {
    $(".DateRangePickerClearIcon").click();
    this.getData();
  };



  render() {
    //console.log(this.state);
    const { SearchBar, ClearSearchButton } = Search;
    return (
      <>
        {/* <div onClick={(e) => this.setState({ error: null })}></div> */}
        {this.state.tableError && <Alert key={'danger'} variant={'danger'} className="tableErrorMsg">
          {this.state.tableError}
        </Alert>}
        <ToolkitProvider
          keyField="id"
          data={this.state.rows}
          columns={this.state.columns}
          bootstrap4
          search
        >
          {
            props => (
              <div>
                <div className="pageTitle">Ενέργειες</div>
                {this.hasCreate && <ModalComponent modalParams={this.createEditModalParams()} />}
                {' '}
                <SearchBar
                  placeholder={Datalanguages["Search"]}
                  {...props.searchProps}
                  className={"searchBox"}
                />
                <ClearSearchButton
                  className="btn-secondary searchBoxClearBtn"
                  text='X'
                  {...props.searchProps}
                />

                <div className="DateRangePickerContainer">
                  <span className="DateRangePickerTitle">φίλτρο ημερομηνίας Από:</span>
                  <DateTimePicker
                    format={"dd / MM / yyyy HH:mm"}
                    clearIcon={<span className="DateRangePickerClearIcon">X</span>}
                    onChange={date => {
                      this.state.filterStartDate = date;
                      this.setState(this.state);
                    }}
                    value={this.state.filterStartDate}
                  />
                  <span className="DateRangePickerTitle">Έως:</span>
                  <DateTimePicker
                    format={"dd / MM / yyyy HH:mm"}
                    clearIcon={<span className="DateRangePickerClearIcon">X</span>}
                    onChange={date => {
                      this.state.filterEndtDate = date;
                      this.setState(this.state);
                    }}
                    value={this.state.filterEndtDate}
                  />
                  <Button className="DateRangePickerBtn" size="sm" onClick={(e) => this.applyFilter(e)}>
                    ΟΚ
                  </Button>
                  <Button className="DateRangePickerBtn btn-secondary" size="sm" onClick={(e) => this.clearFilter(e)}>
                    ΄Ακυρο
                  </Button>
                </div>

                <BootstrapTable
                  defaultSorted={this.state.defaultSorted}
                  key={this.state.rows}
                  {...props.baseProps}
                  keyField='id'
                  data={this.state.rows}
                  columns={this.state.columns}
                  expandRow={this.hasExpand ? this.expandRow : {}}
                  striped
                  hover
                  bordered={false}
                  noDataIndication={this.indication}
                  bootstrap4
                  pagination={paginationFactory({
                    // showTotal:true,
                    sizePerPage: 15
                  })}
                />
              </div>
            )
          }
        </ToolkitProvider>
      </>
    );
  }
}
