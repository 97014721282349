/* eslint-disable */
import React from 'react';
import configData from "../config/config.js";
import { Form } from 'react-bootstrap';
import axios from 'axios';
import ModalComponent from '../components/ModalComponent';
import ErrorModalComponent from '../components/ErrorModalComponent';

//import TableComponent from './TableComponent';
import $ from 'jquery'

import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
//import { Table } from 'react-bootstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import authHeader from '../services/auth-header';
import AuthService from "../services/auth.service";
import { Alert } from 'react-bootstrap';
import Select from 'react-select';
import DateTimePicker from 'react-datetime-picker';
//import Datetime from 'react-datetime';

import '../App.css';

import Datalanguages from "../languages/el.json"

export default class Bottlings extends React.Component {

  //errorMsgRef = React.createRef();

  oneItem = "bottling"
  manyItems = "bottlings"


  user = AuthService.getCurrentUser();
  //isProduser = this.user && ["ROLE_PRODUCER"].some(substring => this.user.roles.includes(substring));
  hasExpand = true;
  hasDelete = false;
  hasEdit = false;
  hasCreate = false;

  state = {
    rows: [],
    getDataPath: configData.API_URL + 'get' + this.manyItems,
    defaultSorted: [{
      dataField: 'transport.time', // if dataField is not match to any column you defined, it will be ignored.
      order: 'desc' // desc or asc
    }],
    columns: [
      {
        dataField: 'code',
        text: "κωδικός παρτίδας",
        sort: true,
        align: 'center',
        headerAlign: 'center',
      },
      {
        dataField: 'wine.name',
        text: "ονομασία",
        sort: true,
        align: 'center',
        headerAlign: 'center',
      },
      {
        dataField: 'transport.fromContainer.name',
        text: "από",
        sort: true,
        align: 'center',
        headerAlign: 'center'
      },
      {
        dataField: 'transport.size',
        text: Datalanguages['size'],
        sort: true,
        align: 'center',
        headerAlign: 'center',
        formatter: (cell, row) => {
          return (
            <span>{cell} L</span>
          );
        },
      },
      {
        dataField: 'transport.time',
        text: "χρόνος",
        sort: true,
        align: 'center',
        headerAlign: 'center',
      }
    ],
  }

  indication() {
    return Datalanguages["Table is Empty"];
  }

  componentDidMount() {
    AuthService.checkUserIsLoggdeIn(this);
    this.getData();
  }

  getData() {
    var _this = this;
    axios.get(_this.state.getDataPath, {
      headers: authHeader(),
    })
      .then(res => {
        _this.state.rows = res.data.Bottlings;
        _this.setState(_this.state);
      })
      .catch(error => {
        console.log(error);
        this.setState({
          tableError: error
        });
      })
  }

  expandRow_DataFormat(row) {
    var vineyards = row.data.vineyards.map((item) =>
      <tr key={item.id}>
        <td>{item.name_or_address}</td>
        <td>{item.percent_content}%</td>
        <td>{item.variety && item.variety.name}</td>
        <td>{item.performance}</td>
        <td>{item.elevation}</td>
      </tr>
    );
    return (
      <>
        <>
          <strong><span>&#8226;</span> Σύνολο φιαλών: </strong> {row.bottles_sum}
          <br />
          <strong><span>&#8226;</span> Aνάλωση: </strong> {row.bestBefore}
          <br />
          <strong><span>&#8226;</span> Αμπελώνες:</strong>
        </>
        <ol>
          <table className="tableVineyards">
            <thead>
              <tr>
                <th> Ονομασία (τοποθεσία) </th>
                <th> Περιεκτικότητα στη συνολική ποσότητα </th>
                <th> Ποικιλία </th>
                <th> Στρεμματική απόδοση </th>
                <th> Υψόμετρο </th>
              </tr>
            </thead>
            <tbody>{vineyards}</tbody>
          </table>
        </ol>
      </>
    );
  }

  expandRow = {
    parentClassName: 'backgroundColorGrey',
    className: 'backgroundColorGrey',
    onlyOneExpanding: true,
    showExpandColumn: false,
    //expandByColumnOnly: true,
    renderer: row => (
      <div>
        {this.expandRow_DataFormat(row)}
        {' '}
        {this.hasDelete && <ModalComponent modalParams={this.deleteModalParams(row)} />}
        {' '}
        {this.hasEdit && <ModalComponent modalParams={this.createEditModalParams(true, row)} />}
      </div>
    )
  }

  render() {
    //console.log(this.state);
    const { SearchBar, ClearSearchButton } = Search;
    return (
      <>
        {this.state.tableError && <Alert key={'danger'} variant={'danger'} className="tableErrorMsg">
          {this.state.tableError}
        </Alert>}
        <ToolkitProvider
          keyField="id"
          data={this.state.rows}
          columns={this.state.columns}
          bootstrap4
          search
        >
          {
            props => (
              <div>
                <div className="pageTitle">Eμφιαλώσεις</div>
                {' '}
                <SearchBar
                  placeholder={Datalanguages["Search"]}
                  {...props.searchProps}
                  className={"searchBox"}
                />
                <ClearSearchButton
                  className="btn-secondary searchBoxClearBtn"
                  text='X'
                  {...props.searchProps}
                />
                <BootstrapTable
                  defaultSorted={this.state.defaultSorted}
                  key={this.state.rows}
                  {...props.baseProps}
                  keyField='id'
                  data={this.state.rows}
                  columns={this.state.columns}
                  expandRow={this.hasExpand ? this.expandRow : {}}
                  striped
                  hover
                  bordered={false}
                  noDataIndication={this.indication}
                  bootstrap4
                  pagination={paginationFactory()}
                />
              </div>
            )
          }
        </ToolkitProvider>
      </>
    );
  }
}
