var errorHandler = function (error) {
  var errorMsg = (error.response &&
    error.response.data &&
    error.response.data.message) ||
    error.message ||
    error.toString();

  return errorMsg;
}

const axios = require('axios');

axios.interceptors.request.use(function (config) {
  // Do something before request is sent
  if (!JSON.parse(localStorage.getItem('user')) && window.location.pathname !== "/login") {
    // console.log("no_user");
    window.location.pathname = "/login";
    window.location.reload();
    return Promise.reject();
  }

  document.getElementById('loadingContainer').style.visibility = "visible";
  //console.log(config);
  return config;
}, function (error) {
  // Do something with request error
  document.getElementById('loadingContainer').style.visibility = "hidden";
  console.log(error);
  return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(function (response) {
  // Do something with response data
  document.getElementById('loadingContainer').style.visibility = "hidden";
  if (response.data.error !== undefined) {
    console.log(response.data.error);
    var errorMsg = response.data.errorMsg !== undefined ? response.data.errorMsg : "Σφάλμα εκτέλεσης εντολής";
    return Promise.reject(errorMsg);
  }
  return response;
}, function (error) {
  // Do something with response error
  document.getElementById('loadingContainer').style.visibility = "hidden";
  console.log(error);
  var err = errorHandler(error);
  console.log(err);
  return Promise.reject(err);
});

module.exports = axios;