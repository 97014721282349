/* eslint-disable */
import React from 'react';
import configData from "../config/config.js";
import { Form } from 'react-bootstrap';
import axios from 'axios';
import ModalComponent from '../components/ModalComponent';
//import TableComponent from './TableComponent';
import $ from 'jquery';

import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
//import { Table } from 'react-bootstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import authHeader from '../services/auth-header';
import AuthService from "../services/auth.service";
import { Alert } from 'react-bootstrap';
import Select from 'react-select';

import '../App.css';

import Datalanguages from "../languages/el.json";

export default class Containers extends React.Component {

  oneItem = "container"
  manyItems = "containers"
  createNewTheTitle = Datalanguages["Create new container"]
  editTheTitle = Datalanguages["Edit container"]
  deleteTheTitle = Datalanguages["Delete container"]


  user = AuthService.getCurrentUser();
  hideVinInfo = this.user && this.user.company && (this.user.company.vininfo == 1) ? false : true;
  hasExpand = true;
  hasDelete = true;
  hasEdit = true;
  hasCreate = true;

  state = {
    rows: [],
    Container_descriptions: [],
    Container_locations: [],
    getDataPath: configData.API_URL + 'get' + this.manyItems,
    createPath: configData.API_URL + 'new' + this.oneItem,
    //createPath: configData.API_URL + 'testNew' + this.oneItem,
    editPath: configData.API_URL + 'edit' + this.oneItem,
    deletePath: configData.API_URL + 'delete' + this.oneItem,
    defaultSorted: [{
      dataField: 'name', // if dataField is not match to any column you defined, it will be ignored.
      order: 'asc' // desc or asc
    }],
    columns: [
      {
        dataField: 'name',
        text: "όνομα",
        sort: true,
        align: 'center',
        headerAlign: 'center'
      },
      {
        dataField: 'content',
        text: "περιεχόμενο",
        sort: true,
        align: 'center',
        headerAlign: 'center'
      },      
      {
        dataField: 'container_description.name',
        text: Datalanguages['description'],
        sort: true,
        align: 'center',
        headerAlign: 'center',
      },
      {
        dataField: 'container_location.name',
        text: Datalanguages['location'],
        sort: true,
        align: 'center',
        headerAlign: 'center',
      },
      {
        hidden: this.hideVinInfo,
        dataField: 'vininfo_temp_co2_id',
        text: "vininfo ID θερμοκρασίας - CO2",
        sort: true,
        align: 'center',
        headerAlign: 'center'
      },
      {
        hidden: this.hideVinInfo,
        dataField: 'vininfo_pressure_id',
        text: "vininfo ID πίεσης",
        sort: true,
        align: 'center',
        headerAlign: 'center'
      },
      {
        dataField: 'lastStage',
        text: "τρέχον στάδιο",
        sort: true,
        align: 'center',
        headerAlign: 'center',
      },
      {
        dataField: 'size',
        text: "χωρητικότητα",
        sort: true,
        align: 'center',
        headerAlign: 'center',
        formatter: (cell, row) => {
          return (
            <span>{cell} L</span>
          );
        },
      },
      // {
      //   dataField: 'temperature',
      //   text: Datalanguages["temperature"],
      //   sort: true,
      //   align: 'center',
      //   headerAlign: 'center',      
      //   formatter: (cell, row) => {
      //     return (
      //     <span>{ cell } ℃</span>
      //     );
      //   },
      // },
      {
        dataField: 'load',
        text: Datalanguages['load'],
        sort: true,
        align: 'center',
        headerAlign: 'center',
        formatter: (cell, row) => {
          var res;
          if (row.size != 0) {
            res = (row.load * 100) / row.size;
            res = res.toFixed(1);
          }
          else {
            res = 0;
          }
          return (
            <span>{cell} ({res}%)</span>
          );
        }
      }
    ]
  }

  createEditModal_container_description_id = undefined;
  createEditModal_container_location_id = undefined;

  indication() {
    return Datalanguages["Table is Empty"];
  }

  componentDidMount() {
    AuthService.checkUserIsLoggdeIn(this);
    this.getData();
  }

  getData() {
    var _this = this;
    axios.get(_this.state.getDataPath, {
      headers: authHeader(),
    })
      .then(res => {
        _this.state.rows = res.data.Containers;
        _this.state.Container_descriptions = res.data.Container_descriptions;
        _this.state.Container_locations = res.data.Container_locations;
        _this.setState(_this.state);
      })
      .catch(error => {
        console.log(error);
        this.setState({
          tableError: error
        });
      })
  }

  createEditModalParams(editMode, row) {
    var _this = this;

    const options_Container_descriptions = this.state.Container_descriptions.map(opt => ({ label: opt.name, value: opt.id }));
    const options_Container_locations = this.state.Container_locations.map(opt => ({ label: opt.name, value: opt.id }));

    return {
      title: editMode ? _this.editTheTitle : _this.createNewTheTitle,
      openBtn: {
        label: editMode ? Datalanguages["EDIT"] : " + ",
        variant: editMode ? "success" : "primary",
        class: editMode ? "modalBtn" : "createBtn",
      },
      okBtn: {
        label: editMode ? Datalanguages["SAVE"] : Datalanguages["CREATE"],
        variant: editMode ? "success" : "primary"
      },
      body:
        <div>
        <Form.Group controlId="createEditModal_container_name" hidden={(row && (row.name == "Σταφυλοδόχος"))}>
          <Form.Label>{"Όνομα"}</Form.Label>
          <Form.Control type="text" defaultValue={editMode === true ? row.name : ""} />
        </Form.Group>
          <Form.Group controlId="createEditModal_container_content" hidden={(row && (row.name == "Σταφυλοδόχος"))}>
            <Form.Label>{"περιεχόμενο"}</Form.Label>
            <Form.Control type="text" defaultValue={editMode === true ? row.content : ""} />
          </Form.Group>
          <Form.Label>{"Περιγραφή"}</Form.Label>
          <Select id="createEditModal_container_description_id"
            // inputValue={"zdfxgsdfg"}
            placeholder={'επιλογή...'}
            formatCreateLabel={(value) => <span>Δημιουργία: "{value}"</span>}
            options={options_Container_descriptions}
            defaultValue={editMode ? options_Container_descriptions.find(option => option.value === row.container_description_id) : undefined}
            onChange={(newValue) => {
              this.createEditModal_container_description_id = newValue.value;
            }}
          />
          <br />
          <Form.Label>{"Τοποθεσία"}</Form.Label>
          <Select id="createEditModal_container_location_id"
            placeholder={'επιλογή...'}
            options={options_Container_locations}
            defaultValue={editMode ? options_Container_locations.find(option => option.value === row.container_location_id) : undefined}
            onChange={(newValue) => {
              this.createEditModal_container_location_id = newValue.value;
            }}
          />
          <br />
          {(((row && (row.name != "Σταφυλοδόχος")) || (editMode != true)) && this.hideVinInfo !== true) &&
            <Form.Group controlId="createEditModal_container_vininfo_temp_co2_id">
              <Form.Label>{"vininfo ID θερμοκρασίας - CO2"}</Form.Label>
              <Form.Control type="text" defaultValue={editMode === true ? row.vininfo_temp_co2_id : ""} />
            </Form.Group>
          }
          {(((row && (row.name != "Σταφυλοδόχος")) || (editMode != true)) && this.hideVinInfo !== true) &&
            <Form.Group controlId="createEditModal_container_vininfo_pressure_id">
              <Form.Label>{"vininfo ID πίεσης"}</Form.Label>
              <Form.Control type="text" defaultValue={editMode === true ? row.vininfo_pressure_id : ""} />
            </Form.Group>
          }
          <Form.Group controlId="createEditModal_container_size">
            <Form.Label>{'Χωρητικότητα σε λίτρα'}</Form.Label>
            <Form.Control type="number" min="0" defaultValue={editMode === true ? row.size : 0} />
          </Form.Group>
        </div>
      ,
      handleOK: function () {
        return editMode === true ? _this.edit(row) : _this.create();
      },
      afterRender: function () {
        if (editMode === true) {
          _this.setInitialValues(row);
        }
      },
    };
  }

  setInitialValues(row) {
    //console.log(row);
    this.createEditModal_container_description_id = row.container_description_id;
    this.createEditModal_container_location_id = row.container_location_id;
  }

  create() {
    return axios.post(
      this.state.createPath,
      {
        name: $("#createEditModal_container_name").val(),
        content: $("#createEditModal_container_content").val(),
        vininfo_temp_co2_id: $("#createEditModal_container_vininfo_temp_co2_id").val(),
        vininfo_pressure_id: $("#createEditModal_container_vininfo_pressure_id").val(),
        container_description_id: this.createEditModal_container_description_id,
        container_location_id: this.createEditModal_container_location_id,
        size: $("#createEditModal_container_size").val()
      },
      { headers: authHeader() }
    ).then(res => {
      //console.log(res);
      if (res.status === 200) {
        this.setState({
          rows: res.data
        });
      }
      return res;
    });
  }

  edit(row) {
    if (row.load > $("#createEditModal_container_size").val()) {
      return Promise.reject('Η επιλογή "χωρητικότητα" δεν είναι σωστή. (το τρέχον περιεχόμενο είναι μεγαλύτερο της νέας χωρητικότητας)');
    }
    return axios.put(
      this.state.editPath,
      {
        id: row.id,
        name: $("#createEditModal_container_name").val(),
        content: $("#createEditModal_container_content").val(),
        vininfo_temp_co2_id: $("#createEditModal_container_vininfo_temp_co2_id").val(),
        vininfo_pressure_id: $("#createEditModal_container_vininfo_pressure_id").val(),
        container_description_id: this.createEditModal_container_description_id,
        container_location_id: this.createEditModal_container_location_id,
        size: $("#createEditModal_container_size").val()
      },
      { headers: authHeader() }
    ).then(res => {
      this.setState({
        rows: []
      });
      this.setState({
        rows: res.data
      });
      return res;
    });
  }

  deleteModalParams(row) {
    var _this = this;
    return {
      title: _this.deleteTheTitle,
      openBtn: {
        label: Datalanguages["DELETE"],
        variant: "danger",
        class: "modalBtn"
      },
      okBtn: {
        label: Datalanguages["DELETE"],
        variant: "danger"
      },
      body: Datalanguages["Are you sure you want to delete container "] + row.name + ";",
      handleOK: function () {
        return _this.delete(row);
      },
      afterRender: function () { },
    };
  }

  delete(row) {
    var ID = row.id;
    if (row.load > 0) {
      return Promise.reject('το παρόν περιεχόμενο είναι μεγαλύτερο του μηδενός');
    }
    return axios.delete(
      this.state.deletePath + "/" + ID,
      { headers: authHeader() }
    ).then(res => {
      console.log(res);
      if (res.status === 200) {
        var tempRows = this.state.rows;
        var pos = tempRows.map(function (e) { return e.id; }).indexOf(ID);
        tempRows.splice(pos, 1);
        this.setState({
          rows: tempRows
        });
      }
      return res;
    })
  }

  handleOnExpand = (row, isExpand, rowIndex, e) => {
    //var _this = this;
    if (isExpand) {
      return axios.post(
        configData.API_URL + 'getContainer_history',
        {
          containerID: row.id,
          dateTimeSelected: new Date()
        },
        { headers: authHeader() }
      ).then(res => {
        var path = res.data.path;
        var ratio = res.data.ratio;
        if (path.length > 0) {
          let tableString = "<table class='tableOriginContainers'> <tr> <th> Ποσότητα </th> <th> Από </th> <th> Προς </th> <th> Χρόνος &#8595</th></tr>";
          let elementsOfPath = "";
          for (let i = 0; i < path.length; i++) {
            let a = path[i];
            let time = a.slice(-22).replaceAll("(", "").replaceAll(")", "");
            a = a.slice(0, -22);
            a = a.replaceAll("ποσότητα: ", "<tr> <td>").replaceAll(" από ", "</td><td>").replaceAll(" προς ", "</td><td>") + "</td><td>" + time + "</td>";
            elementsOfPath = elementsOfPath + a + "</tr>";
          }
          elementsOfPath = elementsOfPath + "</table>"
          path = "<strong>&#8226 Πίνακας προέλευσης περιεχομένου δεξαμενής:</strong><ol>" + tableString + elementsOfPath + "</ol>"
        } else {
          path = "";
        }
        if(ratio.length > 0){
          let tableString = "<table class='tableOriginContainers'> <tr> <th> Από αμπελώνα </th> <th> Ποσότητα </th> <th> Συμμετοχή </th> </tr>";
          let elementsOfPath = "";
          for (let i = 0; i < ratio.length; i++) {
            let a = ratio[i];
            let ratioStringSplit = a.toString().split(' ');
            let litre = "<td>" + ratioStringSplit[ratioStringSplit.length-2] + "</td>";
            let rate = "<td>" + ratioStringSplit[ratioStringSplit.length-1].replaceAll("(","").replaceAll(")","")  + "</td>";

            ratioStringSplit.splice(0,1);
            ratioStringSplit.splice(0,1);
            ratioStringSplit.splice(ratioStringSplit.length - 1, 1);
            ratioStringSplit.splice(ratioStringSplit.length - 1, 1);

            let vineyardName = "<tr><td>" + ratioStringSplit.toString() + "</td>";
            elementsOfPath = elementsOfPath + vineyardName + litre + rate + "</tr>";
          }
          elementsOfPath = elementsOfPath + "</table>"
          ratio = "<strong>&#8226 Πίνακας αναλογιών περιεχομένου δεξαμενής:</strong><ol>"+ tableString + elementsOfPath + "</ol>";  
        }else{
          ratio = "";
        }
        
        var results = path + ratio;
        //console.log(results);
        this.setState({
          [row.id + "container_history"]: results
        });
      })
        .catch(error => {
          console.log(error);
          this.setState({
            [row.id + "container_history"]: '<span style="color:red;">Σφάλμα: ' + error + '</span>'
          });
        });
    }
  }

  expandRow = {
    parentClassName: 'backgroundColorGrey',
    className: 'backgroundColorGrey',
    onlyOneExpanding: true,
    // showExpandColumn: false,
    //expandByColumnOnly: true,
    onExpand: this.handleOnExpand,
    renderer: row => (
      <div>
        {' '}
        {this.hasDelete
          && row.name != 'Σταφυλοδόχος'
          && <ModalComponent modalParams={this.deleteModalParams(row)} />}
        {' '}
        <ModalComponent modalParams={this.createEditModalParams(true, row)} />
        <p dangerouslySetInnerHTML={{ __html: this.state[row.id + "container_history"] }}></p>
      </div>
    )
  }

  render() {
    //console.log(this.state);
    const { SearchBar, ClearSearchButton } = Search;
    return (<>
      {/* <div onClick={(e) => this.setState({ error: null })}></div> */}
      {this.state.tableError && <Alert key={'danger'} variant={'danger'} className="tableErrorMsg">
        {this.state.tableError}
      </Alert>}
      <ToolkitProvider
        keyField="id"
        data={this.state.rows}
        columns={this.state.columns}
        bootstrap4
        search
      >
        {
          props => (
            <div>
              <div className="pageTitle">{Datalanguages["Containers"]}</div>
              {this.hasCreate && <ModalComponent modalParams={this.createEditModalParams()} />}
              {' '}
              <SearchBar
                placeholder={Datalanguages["Search"]}
                {...props.searchProps}
                className={"searchBox"}
              />
              <ClearSearchButton
                className="btn-secondary searchBoxClearBtn"
                text='X'
                {...props.searchProps}
              />
              <BootstrapTable
                defaultSorted={this.state.defaultSorted}
                key={this.state.rows}
                {...props.baseProps}
                keyField='id'
                data={this.state.rows}
                columns={this.state.columns}
                expandRow={this.hasExpand ? this.expandRow : {}}
                striped
                hover
                bordered={false}
                noDataIndication={this.indication}
                bootstrap4
                pagination={paginationFactory({
                  // showTotal:true,
                  sizePerPage: 50
                })}
              />
            </div>
          )
        }
      </ToolkitProvider>
    </>
    );
  }
}
