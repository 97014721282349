/* eslint-disable */
import React from 'react';
import configData from "../config/config.js";
import { Form, OverlayTrigger, Popover } from 'react-bootstrap';
import axios from 'axios';
import ModalComponent from '../components/ModalComponent';
//import TableComponent from './TableComponent';
import $ from 'jquery'

import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
//import { Table } from 'react-bootstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import authHeader from '../services/auth-header';
import AuthService from "../services/auth.service";
import { Alert } from 'react-bootstrap';
import Select from 'react-select';

import '../App.css';

import Datalanguages from "../languages/el.json"

export default class Actions_types extends React.Component {

  oneItem = "actions_type"
  manyItems = "actions_types"
  createNewTheTitle = "Δημιουργία νέου τύπου ενέργειας"
  editTheTitle = "Επεξεργασία τύπου ενέργειας"
  deleteTheTitle = "Διαγραφή τύπου ενέργειας"

  user = AuthService.getCurrentUser();
  isAgronomistORproducer = this.user && ["ROLE_PRODUCER", "ROLE_AGRONOMIST"].some(substring => this.user.roles.includes(substring));
  isProduction_managerORMechanical_engineer = this.user && ["ROLE_PRODUCTION_MANAGER", "ROLE_MECHANICAL_ENGINEER"].some(substring => this.user.roles.includes(substring));
  hasExpand = true;
  hasDelete = true;
  hasEdit = true;
  hasCreate = true;


  state = {
    rows: [],
    getDataPath: configData.API_URL + 'get' + this.manyItems,
    createPath: configData.API_URL + 'new' + this.oneItem,
    //createPath: configData.API_URL + 'testNew' + this.oneItem,
    editPath: configData.API_URL + 'edit' + this.oneItem,
    deletePath: configData.API_URL + 'delete' + this.oneItem,
    defaultSorted: [{
      dataField: 'type', // if dataField is not match to any column you defined, it will be ignored.
      order: 'asc' // desc or asc
    }],
    columns: [
      {
        hidden: (this.isAgronomistORproducer === true || this.isProduction_managerORMechanical_engineer === true) ? true : false,
        dataField: 'source_type.details',
        text: "ενέργεια σε",
        sort: true,
        align: 'center',
        headerAlign: 'center'
      },
      {
        dataField: 'type',
        text: "τύπος ενέργειας",
        sort: true,
        align: 'center',
        headerAlign: 'center',
        formatter: (cell, row) => {
          //console.log(row);
          if (row.is_stage == 1) {
            return (
              <div className="backgroundColorLightBlue">αλλαγή σταδίου σε: {cell}</div>
            );
          }
          else {
            return (
              cell
            );
          }
        }
      },
      {
        dataField: 'description',
        text: "περιγραφή",
        sort: true,
        align: 'center',
        headerAlign: 'center'
      }
    ],
    createEditModal_actions_type_source_type_selected: {},
    createEditModal_actions_type_stage: false,
  };

  createEditModal_actions_type_source_type_data = {};


  indication() {
    return Datalanguages["Table is Empty"];
  }

  componentDidMount() {
    AuthService.checkUserIsLoggdeIn(this);
    this.getData();
  }

  getData() {
    var _this = this;
    axios.get(_this.state.getDataPath, {
      headers: authHeader(),
    })
      .then(res => {
        this.setState({
          rows: res.data.actions_types,
          createEditModal_actions_type_source_type_data: res.data.source_types
        });
      })
      .catch(error => {
        console.log(error);
        this.setState({
          tableError: error
        });
      })
  }

  createEditModalParams(editMode, row) {
    var _this = this;

    return {
      title: editMode ? _this.editTheTitle : _this.createNewTheTitle,
      openBtn: {
        label: editMode ? Datalanguages["EDIT"] : " + ",
        variant: editMode ? "success" : "primary",
        class: editMode ? "modalBtn" : "createBtn",
      },
      okBtn: {
        label: editMode ? Datalanguages["SAVE"] : Datalanguages["CREATE"],
        variant: editMode ? "success" : "primary"
      },
      body:
        <div>
          {!this.isAgronomistORproducer && !this.isProduction_managerORMechanical_engineer && <div>
            <Form.Label>{"Ενέργεια σε"}</Form.Label>
            <Select id="createEditModal_actions_type_source_type"
              placeholder={'επιλογή...'}
              key={_this.state.createEditModal_actions_type_source_type_selected.label}
              options={_this.state.createEditModal_actions_type_source_type_data}
              defaultValue={editMode ? _this.state.createEditModal_actions_type_source_type_selected : undefined}
              onChange={(newValue) => {
                _this.createEditModal_actions_type_source_type = newValue.value;
              }}
            />
            <br />
            <br />
          </div>}
          <div id="createEditModal_actions_type_type_div" style={{}}>
            <Form.Group controlId="createEditModal_actions_type_type">
              <Form.Label>{"Τύπος ενέργειας"}</Form.Label>
              <Form.Control type="text" defaultValue={editMode === true ? row.type : ""} />
            </Form.Group>
            {!this.isAgronomistORproducer && <div>
              <OverlayTrigger trigger="" placement="right" overlay={<Popover id="popover-basic">
                <Popover.Content>
                  Αν η νέα ενέργεια αφορά την αλλαγή σταδίου, στο πλαίσιο "τύπος ενέργειας" συμπληρώστε το όνομα του νέου σταδίου. <br />
                  (Π.χ. για "φιλτράρισμα" η ενέργεια θα εμφανίζεται ως "αλλαγή σταδίου σε φιλτράρισμα".
              </Popover.Content>
              </Popover>}>
                <Form.Group controlId="createEditModal_actions_type_stage">
                  <Form.Check type="checkbox" label="στάδιο"
                    checked={_this.state.createEditModal_actions_type_stage}
                    onChange={(event) => {
                      _this.state.createEditModal_actions_type_stage = !_this.state.createEditModal_actions_type_stage;
                      if (_this.state.createEditModal_actions_type_stage === true) {
                        $("#createEditModal_actions_type_stage_p")[0].hidden = false;
                        $("#createEditModal_actions_type_type_div")[0].style.border = "solid 1px #c9c9c9";
                        $("#createEditModal_actions_type_type_div")[0].style.padding = "10px";
                      }
                      else {
                        $("#createEditModal_actions_type_stage_p")[0].hidden = true;
                        $("#createEditModal_actions_type_type_div")[0].style.border = "";
                        $("#createEditModal_actions_type_type_div")[0].style.padding = "";
                      }
                      _this.forceRerender = true;
                      _this.setState(_this.state);
                    }}
                  />
                </Form.Group>
              </OverlayTrigger>
              <p id="createEditModal_actions_type_stage_p" hidden={true}>
                Αν η νέα ενέργεια αφορά την αλλαγή σταδίου, στο πλαίσιο "τύπος ενέργειας" συμπληρώστε το όνομα του νέου σταδίου.
                Παράδειγμα για "φιλτράρισμα" η ενέργεια θα εμφανίζεται ως "αλλαγή σταδίου σε φιλτράρισμα".
              </p>
            </div>}
          </div>
          <br />
          <Form.Group controlId="createEditModal_actions_type_description">
            <Form.Label>{"Περιγραφή"}</Form.Label>
            <Form.Control as="textarea" rows="2" defaultValue={editMode === true ? row.description : ""} />
          </Form.Group>
        </div>
      ,
      handleOK: function () {
        return editMode === true ? _this.edit(row) : _this.create();
      },
      afterShow: function () {
        _this.forceRerender = false;
        if (editMode === true) {
          _this.state.createEditModal_actions_type_source_type_selected =
            _this.state.createEditModal_actions_type_source_type_data.find(el => {
              return el.value === row.source_type_id;
            });
          _this.state.createEditModal_actions_type_stage = row.is_stage === 0 ? false : true;
          _this.setState(_this.state);
        }
        else {
          _this.state.createEditModal_actions_type_stage = false;
          _this.setState(_this.state);
        }
      },
      afterRender: function () {
        if (editMode === true && !(_this.forceRerender === true)) {
          _this.setInitialValues(row);
        }
      },
    };
  }

  setInitialValues(row) {
    //console.log(row);
  }

  create() {
    if (this.isAgronomistORproducer) {
      var tempcreateEditModal_actions_type_source_type = this.state.createEditModal_actions_type_source_type_data.find(el => {
        return el.label === "αμπελώνας";
      });
      this.createEditModal_actions_type_source_type = tempcreateEditModal_actions_type_source_type.value;
    }
    if (this.isProduction_managerORMechanical_engineer) {
      var tempcreateEditModal_actions_type_source_type = this.state.createEditModal_actions_type_source_type_data.find(el => {
        return el.label === "οινοποιείο";
      });
      this.createEditModal_actions_type_source_type = tempcreateEditModal_actions_type_source_type.value;
    }
    return axios.post(
      this.state.createPath,
      {
        source_type_id: this.createEditModal_actions_type_source_type,
        type: $("#createEditModal_actions_type_type").val(),
        description: $("#createEditModal_actions_type_description").val(),
        is_stage: this.state.createEditModal_actions_type_stage
      },
      { headers: authHeader() }
    ).then(res => {
      //console.log(res);
      if (res.status === 200) {
        this.setState({
          rows: res.data.actions_types,
          createEditModal_actions_type_source_type_data: res.data.source_types
        });
      }
      return res;
    });
  }

  edit(row) {
    if (this.isAgronomistORproducer) {
      var tempcreateEditModal_actions_type_source_type = this.state.createEditModal_actions_type_source_type_data.find(el => {
        return el.label === "αμπελώνας";
      });
      this.createEditModal_actions_type_source_type = tempcreateEditModal_actions_type_source_type.value;
    }
    if (this.isProduction_managerORMechanical_engineer) {
      var tempcreateEditModal_actions_type_source_type = this.state.createEditModal_actions_type_source_type_data.find(el => {
        return el.label === "οινοποιείο";
      });
      this.createEditModal_actions_type_source_type = tempcreateEditModal_actions_type_source_type.value;
    }
    return axios.put(
      this.state.editPath,
      {
        id: row.id,
        source_type_id: this.createEditModal_actions_type_source_type,
        type: $("#createEditModal_actions_type_type").val(),
        description: $("#createEditModal_actions_type_description").val(),
        is_stage: this.state.createEditModal_actions_type_stage
      },
      { headers: authHeader() }
    ).then(res => {
      //console.log(res);
      if (res.status === 200) {
        this.setState({
          rows: res.data.actions_types,
          createEditModal_actions_type_source_type_data: res.data.source_types
        });
      }
      return res;
    });
  }

  deleteModalParams(row) {
    var _this = this;
    return {
      title: _this.deleteTheTitle,
      openBtn: {
        label: Datalanguages["DELETE"],
        variant: "danger",
        class: "modalBtn"
      },
      okBtn: {
        label: Datalanguages["DELETE"],
        variant: "danger"
      },
      body: "Θέλετε να διαγράψετε τον τύπο ενέργειας: " + row.type + ";",
      handleOK: function () {
        return _this.delete(row.id);
      },
      afterRender: function () { },
    };
  }

  delete(ID) {
    return axios.delete(
      this.state.deletePath + "/" + ID,
      { headers: authHeader() }
    ).then(res => {
      //console.log(res);
      if (res.status === 200) {
        this.setState({
          rows: res.data.actions_types,
          createEditModal_actions_type_source_type_data: res.data.source_types
        });
      }
      return res;
    });
  }

  expandRow = {
    parentClassName: 'backgroundColorGrey',
    className: 'backgroundColorGrey',
    onlyOneExpanding: true,
    showExpandColumn: false,
    //expandByColumnOnly: true,
    renderer: row => (
      <div>
        {' '}
        {this.hasDelete && <ModalComponent modalParams={this.deleteModalParams(row)} />}
        {' '}
        {this.hasEdit && <ModalComponent modalParams={this.createEditModalParams(true, row)} />}
      </div>
    )
  }

  render() {
    //console.log(this.state);
    const { SearchBar, ClearSearchButton } = Search;
    return (
      <>
        {/* <div onClick={(e) => this.setState({ error: null })}></div> */}
        {this.state.tableError && <Alert key={'danger'} variant={'danger'} className="tableErrorMsg">
          {this.state.tableError}
        </Alert>}
        <ToolkitProvider
          keyField="id"
          data={this.state.rows}
          columns={this.state.columns}
          bootstrap4
          search
        >
          {
            props => (
              <div>
                <div className="pageTitle">Τύποι ενεργειών</div>
                {this.hasCreate && <ModalComponent modalParams={this.createEditModalParams()} />}
                {' '}
                <SearchBar
                  placeholder={Datalanguages["Search"]}
                  {...props.searchProps}
                  className={"searchBox"}
                />
                <ClearSearchButton
                  className="btn-secondary searchBoxClearBtn"
                  text='X'
                  {...props.searchProps}
                />
                <BootstrapTable
                  defaultSorted={this.state.defaultSorted}
                  key={this.state.rows}
                  {...props.baseProps}
                  keyField='id'
                  data={this.state.rows}
                  columns={this.state.columns}
                  expandRow={this.hasExpand ? this.expandRow : {}}
                  striped
                  hover
                  bordered={false}
                  noDataIndication={this.indication}
                  bootstrap4
                  pagination={paginationFactory()}
                />
              </div>
            )
          }
        </ToolkitProvider>
      </>
    );
  }
}
