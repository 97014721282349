import axios from 'axios';
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import authHeader from '../services/auth-header';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery'

class CSVuploader extends Component {

    state = {
        selectedFile: null
    };

    onFileChange = event => {
        const reader = new FileReader()
        reader.onload = async (event) => {
            const text = (event.target.result)
            //console.log(text);
            this.setState({ selectedFile: text });
        };
        reader.readAsText(event.target.files[0]);
    };

    onFileUpload = () => {                  
        if (this.state.selectedFile != null) {
            axios.post(
                this.props.uploadUrl,
                {
                    csv_measurement_data: this.state.selectedFile
                },
                { headers: authHeader() }
            )
                .then(res => {
                    this.setState({ selectedFile: null });  
                    this.props.updateGrid(); 
                    toast.success("αποστολή csv: επιτυχής");
                    //console.log(res);
                })
                .catch(err => {            
                    toast.error("αποστολή csv: σφάλμα");
                    console.log(err);
                })
                .finally(() => {
                    $("#csvInput").val(null);   
                });
        }
    };

    render() {
        return (
            <div className={this.props.containerClass}>
                μετρήσεις Αναλυτή σε csv: <input id="csvInput" type="file" onChange={this.onFileChange} />
                <Button className={this.props.okBtnClass} size="sm" onClick={this.onFileUpload}>
                    αποστολή
                </Button>
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </div>
        );
    }
}

export default CSVuploader;
