/* eslint-disable */
import React from 'react';
import moment from 'moment';
import configData from "../config/config.js";
import { Form } from 'react-bootstrap';
import axios from 'axios';
import ModalComponent from '../components/ModalComponent';
//import TableComponent from './TableComponent';
import $ from 'jquery'

import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
//import { Table } from 'react-bootstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import authHeader from '../services/auth-header';
import AuthService from "../services/auth.service";
import { Alert } from 'react-bootstrap';
import Select from 'react-select';
import DateTimePicker from 'react-datetime-picker';
import { Button } from 'react-bootstrap';
import CSVuploader from '../components/CSVuploader';  

import '../App.css';

import Datalanguages from "../languages/el.json"

export default class Measurements extends React.Component {
  oneItem = "measurement";
  manyItems = "measurements";
  createNewTheTitle = "Εγγραφή νέας μέτρησης";
  editTheTitle = "Επεξεργασία μέτρησης";
  deleteTheTitle = "Διαγραφή μέτρησης";


  user = AuthService.getCurrentUser();
  hideuploadCSV = this.user && this.user.company && (this.user.company.uploadCSV == 1) ? false : true ;
  isAgronomist = this.user && ["ROLE_AGRONOMIST"].some(substring => this.user.roles.includes(substring));
  isProducer = this.user && ["ROLE_PRODUCER"].some(substring => this.user.roles.includes(substring));
  hasExpand = !this.isProducer;
  hideVinInfo = this.user && this.user.company && (this.user.company.vininfo == 1) ? false : true;
  hideuploadCSV = this.user && this.user.company && (this.user.company.uploadCSV == 1) ? false : true;
  hideTH2E = this.user && this.user.company && (this.user.company.TH2E == 1) ? false : true;
  isAgronomistORproducer = this.user && ["ROLE_PRODUCER", "ROLE_AGRONOMIST"].some(substring => this.user.roles.includes(substring));
  hasMeasurementsExtraFilters = !this.isAgronomistORproducer;
  hasDelete = !this.isProducer;
  hasEdit = !this.isProducer;
  hasCreate = !this.isProducer;

  state = {
    rows: [],
    getDataPath: configData.API_URL + 'get' + this.manyItems,
    createPath: configData.API_URL + 'new' + this.oneItem,
    //createPath: configData.API_URL + 'testNew' + this.oneItem,
    editPath: configData.API_URL + 'edit' + this.oneItem,
    deletePath: configData.API_URL + 'delete' + this.manyItems,
    defaultSorted: [{
      dataField: 'datetime_created', // if dataField is not match to any column you defined, it will be ignored.
      order: 'desc' // desc or asc
    }],
    columns: [
      {
        dataField: 'source',
        text: "λήψη μέτρησης από",
        sort: true,
        align: 'center',
        headerAlign: 'center'
      },
      {
        dataField: 'measurment_type',
        text: "τύπος μέτρησης",
        sort: true,
        align: 'center',
        headerAlign: 'center'
      },
      {
        dataField: 'data',
        text: "τιμή",
        sort: true,
        align: 'center',
        headerAlign: 'center',
        formatter: (cell, row) => {
          if (
            row.measurment_type.includes('καιρικές συνθήκες (OpenWeatherMap)')
            || row.measurment_type.includes('μετρήσεις από VinInfo')
            || row.measurment_type.includes('μετρήσεις από TH2E')
            || row.measurment_type.includes('μετρήσεις από csv Αναλυτή')
          ) {
            var res = cell.replaceAll("|", "<br />");
            return (
              <pre style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: res }}></pre>
            );
          }
          else {
            return cell;
          }
        },
      },
      {
        dataField: 'unit',
        text: "μονάδα μέτρησης",
        sort: true,
        align: 'center',
        headerAlign: 'center'
      },
      {
        hidden: (!this.isAgronomist === true && !this.isProducer === true) ? false : true,
        dataField: 'stage',
        text: "στάδιο",
        sort: true,
        align: 'center',
        headerAlign: 'center'
      },
      {
        dataField: 'datetime_created',
        text: "χρόνος",
        sort: true,
        align: 'center',
        headerAlign: 'center',
        // headerFormatter: (o) => {
        //   return (
        //     <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-clock" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        //       <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm8-7A8 8 0 1 1 0 8a8 8 0 0 1 16 0z" />
        //       <path fill-rule="evenodd" d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z" />
        //     </svg>
        //   );
        // },
      }
    ],
    createEditModal_measurement_time: new Date(),
    createEditModal_measurement_From_data: [],
    createEditModal_measurement_types_data: [],
    createEditModal_measurement_From_selected: {},
    createEditModal_measurement_type_selected: {},
    filterStartDate: null,
    filterEndtDate: null,
    displayOpenWeatherMapMeasurements: false,
    displayVinInfo: false,
    displayFromCsvMeasurements: true,
  };

  getDataForEditmeasurements = configData.API_URL + 'getDataForNewOrEditMeasurements';
  getDataForNewmeasurements = configData.API_URL + 'getDataForNewOrEditMeasurements';
  createEditModal_measurement_Fromval = {};
  createEditModal_measurement_type = {};



  indication() {
    return Datalanguages["Table is Empty"];
  }

  componentDidMount() {
    AuthService.checkUserIsLoggdeIn(this);
    this.getData();
  }

  getData() {    
    var _this = this;
    var params = {      
      startDateRangePicker: _this.state.filterStartDate,
      endDateRangePicker: _this.state.filterEndtDate,
      displayOpenWeatherMapMeasurements: _this.state.displayOpenWeatherMapMeasurements,
      displayVinInfo: _this.state.displayVinInfo,
      displayFromCsvMeasurements: _this.state.displayFromCsvMeasurements,
    }
    axios.post(_this.state.getDataPath,
      params,
      {
        headers: authHeader(),
      })
      .then(res => {
        _this.state.rows = res.data;
        _this.setState(_this.state);
      })
      .catch(error => {
        console.log(error);
        this.setState({
          tableError: error
        });
      })
  }

  handleClickFilter = () => {
    this.setState(this.state);
    this.getData();
  };

  createEditModalParams(editMode, row) {
    var _this = this;
    return {
      title: editMode ? _this.editTheTitle : _this.createNewTheTitle,
      openBtn: {
        label: editMode ? Datalanguages["EDIT"] : " + ",
        variant: editMode ? "success" : "primary",
        class: editMode ? "modalBtn" : "createBtn",
      },
      okBtn: {
        label: editMode ? Datalanguages["SAVE"] : Datalanguages["CREATE"],
        variant: editMode ? "success" : "primary"
      },
      body:
        <div>
          <Form.Label>{"Λήψη μέτρησης από"}</Form.Label>
          <Select id="createEditModal_measurement_From"
            placeholder={'επιλογή...'}
            key={_this.state.createEditModal_measurement_From_selected.label}
            options={_this.state.createEditModal_measurement_From_data}
            defaultValue={editMode ? _this.state.createEditModal_measurement_From_selected : undefined}
            onChange={(newValue) => {
              _this.createEditModal_measurement_Fromval = newValue.value;
              _this.state.createEditModal_measurement_types_data_filtered = _this.state.createEditModal_measurement_types_data.filter(el => {
                return el.value.source_type_id == _this.createEditModal_measurement_Fromval.source_type_id;
              });
              _this.forceRerender = true;
              _this.setState(_this.state);
            }}
          />
          <br />
          <Form.Label>{"Τύπος μέτρησης"}</Form.Label>
          <Select id="createEditModal_measurement_type"
            placeholder={'επιλογή...'}
            key={_this.state.createEditModal_measurement_type_selected.label}
            options={_this.state.createEditModal_measurement_types_data_filtered}
            defaultValue={editMode ? _this.state.createEditModal_measurement_type_selected : undefined}
            onChange={(newValue) => {
              _this.createEditModal_measurement_type = newValue.value;
            }}
          />
          <br />
          <div>
            <Form.Group controlId="createEditModal_measurement_data">
              <Form.Label>{"Τιμή"}</Form.Label>
              <Form.Control as="textarea" rows="1" defaultValue={editMode === true ? row.data : ""} />
            </Form.Group>
          </div>
          <Form.Label>{"Χρόνος"}</Form.Label>
          <br />
          <DateTimePicker
            format={"dd / MM / yyyy HH:mm"}
            clearIcon={null}
            onChange={date => { this.setState({ createEditModal_measurement_time: date }); }}
            value={new Date(this.state.createEditModal_measurement_time)}
          />
        </div>
      ,
      handleOK: function () {
        return editMode === true ? _this.edit(row) : _this.create();
      },
      afterShow: function () {
        _this.forceRerender = false;
        var getDataUrl = editMode === true ? _this.getDataForEditmeasurements : _this.getDataForNewmeasurements;
        return axios.get(getDataUrl, { headers: authHeader() })
          .then(res => {
            _this.state.createEditModal_measurement_From_data = res.data.source;
            _this.state.createEditModal_measurement_types_data = res.data.measurement_types;
            _this.state.createEditModal_measurement_types_data_filtered = JSON.parse(JSON.stringify(res.data.measurement_types));
            if (editMode === true) {
              _this.state.createEditModal_measurement_time = moment(row.datetime_created).utcOffset(2);
              _this.state.createEditModal_measurement_From_selected =
                _this.state.createEditModal_measurement_From_data.find((option) => {
                  return option.value.source_id === row.source_id &&
                    option.value.source_type_id === row.source_type_id;
                });
              _this.state.createEditModal_measurement_type_selected =
                _this.state.createEditModal_measurement_types_data.find((option) => {
                  return option.value.id == row.measurment_type_id;
                });
            }
            else {
              _this.state.createEditModal_measurement_time = new Date();
              // _this.state.createEditModal_transport_From_selected = {};
              // _this.state.createEditModal_transport_To_selected = {};
            }
            _this.setState(_this.state);
          });
      },
      afterRender: function () {
        if (editMode === true && !(_this.forceRerender === true)) {
          _this.setInitialValues(row);
        }
      },
    };
  }

  setInitialValues(row) {
    //console.log(row);
    this.createEditModal_measurement_Fromval = {
      source_id: row.source_id,
      source_type_id: row.source_type_id,
    };
    this.createEditModal_measurement_type = row.measurment_type_id;
    this.createEditModal_transport_time = row.time;
  }

  create() {
    return axios.post(
      this.state.createPath,
      {
        source_id: this.createEditModal_measurement_Fromval.source_id,
        source_type_id: this.createEditModal_measurement_Fromval.source_type_id,
        measurment_type_id: this.createEditModal_measurement_type.id,
        datetime_created: this.state.createEditModal_measurement_time,
        data: $("#createEditModal_measurement_data").val(),
      },
      { headers: authHeader() }
    ).then(res => {
      // console.log(res);
      if (res.status === 200) {
        this.setState({
          rows: res.data,
        });
      }
      return res;
    });
  }

  edit(row) {
    // console.log(this.createEditModal_measurement_Fromval);
    return axios.put(
      this.state.editPath,
      {
        id: row.id,
        source_id: this.createEditModal_measurement_Fromval.source_id,
        source_type_id: this.createEditModal_measurement_Fromval.source_type_id,
        measurment_type_id: this.createEditModal_measurement_type.id,
        datetime_created: this.state.createEditModal_measurement_time,
        data: $("#createEditModal_measurement_data").val(),
      },
      { headers: authHeader() }
    ).then(res => {
      // console.log(res);
      if (res.status === 200) {
        this.setState({
          rows: res.data,
        });
      }
      return res;
    });
  }

  deleteModalParams(row) {
    var _this = this;
    // console.log(row);
    return {
      title: _this.deleteTheTitle,
      openBtn: {
        label: Datalanguages["DELETE"],
        variant: "danger",
        class: "modalBtn"
      },
      okBtn: {
        label: Datalanguages["DELETE"],
        variant: "danger"
      },
      body: "Θέλετε να διαγράψετε τη μέτρηση: " + row.measurment_type + " σε " + row.source + " " + row.data + " " + row.unit + ";",
      handleOK: function () {
        return _this.delete(row.id);
      },
      afterRender: function () { },
    };
  }

  delete(ID) {
    return axios.delete(
      this.state.deletePath + "/" + ID,
      { headers: authHeader() }
    ).then(res => {
      // console.log(res);
      if (res.status === 200) {
        this.setState({
          rows: res.data,
        });
      }
      return res;
    });
  }

  expandRow = {
    parentClassName: 'backgroundColorGrey',
    className: 'backgroundColorGrey',
    onlyOneExpanding: true,
    showExpandColumn: false,
    //expandByColumnOnly: true,
    renderer: row => (
      <div>

        {' '}
        {this.hasDelete && <ModalComponent modalParams={this.deleteModalParams(row)} />}
        {' '}
        {this.hasEdit
          && !row.measurment_type.includes('καιρικές συνθήκες (OpenWeatherMap)')
          && !row.measurment_type.includes('μετρήσεις από VinInfo')
          && !row.measurment_type.includes('μετρήσεις από TH2E')
          && !row.measurment_type.includes('μετρήσεις από csv Αναλυτή')
          && <ModalComponent modalParams={this.createEditModalParams(true, row)} />}
      </div>
    )
  }


  applyFilter(event, picker) {
    this.getData();
  };

  clearFilter() {
    $(".DateRangePickerClearIcon").click();
    this.getData();
  };



  render() {
    //console.log(this.state);
    const { SearchBar, ClearSearchButton } = Search;
    var _this = this;
    return (<>
      {/* <div onClick={(e) => this.setState({ error: null })}></div> */}
      {this.state.tableError && <Alert key={'danger'} variant={'danger'} className="tableErrorMsg">
        {this.state.tableError}
      </Alert>}
      <ToolkitProvider
        keyField="id"
        data={this.state.rows}
        columns={this.state.columns}
        bootstrap4
        search
      >
        {
          props => (
            <div>
              <div className="pageTitle">Μετρήσεις</div>
              {this.hasCreate && <ModalComponent modalParams={this.createEditModalParams()} />}
              {' '}
              <SearchBar
                placeholder={Datalanguages["Search"]}
                {...props.searchProps}
                className={"searchBox"}
              />
              <ClearSearchButton
                className="btn-secondary searchBoxClearBtn"
                text='X'
                {...props.searchProps}
              />

              <div className="DateRangePickerContainer">
                <span className="DateRangePickerTitle">φίλτρο ημερομηνίας Από:</span>
                <DateTimePicker
                  format={"dd / MM / yyyy HH:mm"}
                  clearIcon={<span className="DateRangePickerClearIcon">X</span>}
                  onChange={date => {
                    this.state.filterStartDate = date;
                    this.setState(this.state);
                  }}
                  value={this.state.filterStartDate}
                />
                <span className="DateRangePickerTitle">Έως:</span>
                <DateTimePicker
                  format={"dd / MM / yyyy HH:mm"}
                  clearIcon={<span className="DateRangePickerClearIcon">X</span>}
                  onChange={date => {
                    this.state.filterEndtDate = date;
                    this.setState(this.state);
                  }}
                  value={this.state.filterEndtDate}
                />
                <Button className="DateRangePickerBtn" size="sm" onClick={(e) => this.applyFilter(e)}>
                  ΟΚ
                  </Button>
                <Button className="DateRangePickerBtn btn-secondary" size="sm" onClick={(e) => this.clearFilter(e)}>
                  ΄Ακυρο
                  </Button>
              </div>

              <div className="displayControleContainer displayDashBoardControleContainer">
                  {this.hasMeasurementsExtraFilters && this.hideVinInfo !== true && <Form.Check type="checkbox" label="μετρήσεις από VinInfo"
                    checked={this.state.displayVinInfo}
                    onChange={(event) => {
                      this.state.displayVinInfo = !this.state.displayVinInfo;
                      this.handleClickFilter();
                    }}
                  />}
                  <Form.Check type="checkbox" label="καιρικές συνθήκες (OpenWeatherMap)"
                    checked={this.state.displayOpenWeatherMapMeasurements}
                    onChange={(event) => {
                      this.state.displayOpenWeatherMapMeasurements = !this.state.displayOpenWeatherMapMeasurements;
                      this.handleClickFilter();
                    }}
                  />
                  {this.hasMeasurementsExtraFilters && this.hideuploadCSV !== true && <Form.Check type="checkbox" label="μετρήσεις από csv Αναλυτή"
                    checked={this.state.displayFromCsvMeasurements}
                    onChange={(event) => {
                      this.state.displayFromCsvMeasurements = !this.state.displayFromCsvMeasurements;
                      this.handleClickFilter();
                    }}
                  />}
                </div>

              <br />
              {(!this.isAgronomist === true && !this.isProducer === true && this.hideuploadCSV !== true) 
              && <CSVuploader
                containerClass="csvUploaderContainer"
                okBtnClass="csvUploaderOkBtnClass"
                uploadUrl={configData.API_URL + "csvSamples"}
                updateGrid={ function () {
                  return _this.applyFilter();
                }}
              />}

              <BootstrapTable
                defaultSorted={this.state.defaultSorted}
                key={this.state.rows}
                {...props.baseProps}
                keyField='id'
                data={this.state.rows}
                columns={this.state.columns}
                expandRow={this.hasExpand ? this.expandRow : {}}
                striped
                hover
                bordered={false}
                noDataIndication={this.indication}
                bootstrap4
                pagination={paginationFactory({
                  // showTotal:true,
                  sizePerPage: 15
                })}
              />
            </div>
          )
        }
      </ToolkitProvider>
    </>
    );
  }
}
