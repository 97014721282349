/* eslint-disable */
import React from 'react';
import configData from "../config/config.js";
import axios from 'axios';
import ModalComponent from '../components/ModalComponent';
import ErrorModalComponent from '../components/ErrorModalComponent';

//import TableComponent from './TableComponent';
import $ from 'jquery'

import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
//import { Table } from 'react-bootstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import authHeader from '../services/auth-header';
import AuthService from "../services/auth.service";
import { Alert } from 'react-bootstrap';
import DateTimePicker from 'react-datetime-picker';
import { Form, Button } from 'react-bootstrap';
import Select from 'react-select';

import '../App.css';

import Datalanguages from "../languages/el.json"

export default class Dashboard extends React.Component {

  //errorMsgRef = React.createRef();

  user = AuthService.getCurrentUser();
  hideVinInfo = this.user && this.user.company && (this.user.company.vininfo == 1) ? false : true;
  hideuploadCSV = this.user && this.user.company && (this.user.company.uploadCSV == 1) ? false : true;
  hideTH2E = this.user && this.user.company && (this.user.company.TH2E == 1) ? false : true;
  isAgronomistORproducer = this.user && ["ROLE_PRODUCER", "ROLE_AGRONOMIST"].some(substring => this.user.roles.includes(substring));
  isProduction_managerORMechanical_engineer = this.user && ["ROLE_PRODUCTION_MANAGER", "ROLE_MECHANICAL_ENGINEER"].some(substring => this.user.roles.includes(substring));
  hasVineyadsSelect = !this.isProduction_managerORMechanical_engineer;
  hasContainersSelect = !this.isAgronomistORproducer;
  hasBottlingsSelect = !this.isAgronomistORproducer;
  hasMeasurementsExtraFilters = !this.isAgronomistORproducer;
  hasExpand = false;
  hasDelete = false;
  hasEdit = false;
  hasCreate = false;
  searchTerm = "";
  searchTermsArr = [];

  state = {
    rows: [],
    getDataPath: configData.API_URL + 'get_DataHistory',
    get_options_ForSelectAtHistory: configData.API_URL + "get_options_ForSelectAtHistory",
    bottlingHistory: configData.API_URL + "bottlingHistory",
    defaultSorted: [{
      dataField: 'time', // if dataField is not match to any column you defined, it will be ignored.
      order: 'desc' // desc or asc
    }],
    columns: [
      {
        dataField: 'typeTitle',
        text: "τύπος",
        sort: true,
        align: 'center',
        headerAlign: 'center',
        formatter: (cell, row) => {
          return (
            <>
              <Button variant="link" onClick={(e) => {
                this.searchTerm = cell;
                $(".triggerSearch").click();
              }}>
                {cell}</Button>
            </>);
        },
      },
      {
        dataField: 'source',
        text: "από",
        sort: true,
        align: 'center',
        headerAlign: 'center',
        formatter: (cell, row) => {
          return (
            <>
              <Button variant="link" onClick={(e) => {
                this.searchTerm = cell;
                $(".triggerSearch").click();
              }}>
                {cell}</Button>
            </>);
        },
      },
      {
        dataField: 'data',
        text: "δεδομένα",
        sort: true,
        align: 'center',
        headerAlign: 'center',
        formatter: (cell, row) => {
          if (
            cell.includes('καιρικές συνθήκες (OpenWeatherMap)')
            || cell.includes('μετρήσεις από VinInfo')
            || cell.includes('μετρήσεις από TH2E')
            || cell.includes('μετρήσεις από csv Αναλυτή')
          ) {
            var res = cell.replaceAll("|", "<br />");
            return (
              <pre style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: res }}></pre>
            );
          }
          else {
            return cell;
          }
        },
      },
      {
        dataField: 'time',
        text: "χρόνος",
        sort: true,
        align: 'center',
        headerAlign: 'center',
        formatter: (cell, row) => {
          return (
            <>
              <Button variant="link" onClick={(e) => {
                this.searchTerm = cell.substring(0, 10);
                $(".triggerSearch").click();
              }}>
                {cell.substring(0, 10)}
              </Button>
              {cell.substring(10)}
            </>);
        },
      },
    ],
    selectOptions: [],
    vineyardsSelectOptions: [],
    containersSelectOptions: [],
    selected: {},
    filterStartDate: null,
    filterEndtDate: null,
    source_type_id_history: null,
    displayActions: true,
    displayMeasurements: true,
    displayTransports: true,
    displayOpenWeatherMapMeasurements: false,
    displayVinInfo: false,
    displayFromCsvMeasurements: true,
    extraData_path: "",
  }

  indication() {
    return Datalanguages["Table is Empty"];
  }

  async componentDidMount() {
    AuthService.checkUserIsLoggdeIn(this);
    await this.get_options_ForSelectAtHistory();
    await this.getData();
  }

  async get_options_ForSelectAtHistory() {
    var _this = this;
    await axios.get(_this.state.get_options_ForSelectAtHistory,
      {
        headers: authHeader(),
      })
      .then(res => {
        _this.state.containersSelectOptions = res.data.containers;
        _this.state.vineyardsSelectOptions = res.data.vineyards;
        _this.state.bottlingsSelectOptions = res.data.bottlings;
        _this.setState(_this.state);
      })
      .catch(error => {
        console.log(error);
        this.setState({
          tableError: error
        });
      });
  }

  async getData() {
    var _this = this;
    var params = {
      source_id_history: _this.state.selected.value, // check at API
      source_type_id_history: _this.state.source_type_id_history,
      startDateRangePicker: _this.state.filterStartDate,
      endDateRangePicker: _this.state.filterEndtDate,
      displayActions: _this.state.displayActions,
      displayMeasurements: _this.state.displayMeasurements,
      displayTransports: _this.state.displayTransports,
      displayOpenWeatherMapMeasurements: _this.state.displayOpenWeatherMapMeasurements,
      displayVinInfo: _this.state.displayVinInfo,
      displayFromCsvMeasurements: _this.state.displayFromCsvMeasurements,
    }
    await axios.post(_this.state.source_type_id_history == "bottling" 
    ||  _this.state.source_type_id_history == "fakeBottling"
    ? _this.state.bottlingHistory : _this.state.getDataPath,
      params,
      {
        headers: authHeader(),
      })
      .then(res => {
        if (_this.state.source_type_id_history == "bottling" 
        ||  _this.state.source_type_id_history == "fakeBottling") {
          _this.state.rows = res.data.data;
          var path = res.data.extraData.path;
          if (path.length > 0) {
            _this.state.extraData_path = "<br /> <table class='tableOriginDashboard'> <tr> <th> Ποσότητα </th> <th> Από </th> <th> Προς </th> <th> Χρόνος &#8595</th></tr>";
            var open_tag = "<strong><a href='#' class='fakeLink'>";
            var close_tag = "</a></strong>";
            var elementsOfPath = "";
            for (let i = 0; i < path.length; i++) {
              var a = path[i];
              var time = a.slice(-22).replaceAll("(", "").replaceAll(")", "");
              a = a.slice(0, -22);
              a = a.replaceAll("ποσότητα: ", "<tr> <td>").replaceAll(" από ", "</td><td>" + open_tag).replaceAll(" προς ", "</td><td>" + open_tag) + "</td><td>" + time + "</td>";
              a = a.replaceAll(" (αμπελώνας)", " (αμπελώνας)" + close_tag);
              a = a.replaceAll(" (δεξαμενή)", " (δεξαμενή)" + close_tag);
              elementsOfPath = elementsOfPath + a + "</tr>";
            }
            elementsOfPath = elementsOfPath + " </table>"
            _this.state.extraData_path = "<br /><button class='pathContainer'>Πίνακας προέλευσης</button><ol class='pathContent'>" + _this.state.extraData_path + elementsOfPath
          }
          else {
            _this.state.extraData_path = "";
          }
        }
        else {
          _this.state.rows = res.data;
          _this.state.extraData_path = "";
        }
        _this.setState(_this.state);
        $(".fakeLink").on('click', function (e) {
          _this.searchTerm = e.target.innerText;
          $(".triggerSearch").click();
        });
        $( ".pathContainer" ).click(function() {
          $( ".pathContent" ).toggle();
        });
      })
      .catch(error => {
        console.log(error);
        this.setState({
          tableError: error
        });
      });
  }


  // expandRow_DataFormat(row) {
  //   var vineyards = row.data.vineyards.map((item) =>
  //     <li key={item.id}>
  //       {Datalanguages["name or address"]}: {item.name_or_address}
  //       <br />
  //       περιεκτικότητα στη συνολική ποσότητα: {item.percent_content}%
  //       <br />
  //       ποικιλία: {item.variety && item.variety.name}
  //       <br />
  //       στρεμματική απόδοση: {item.performance}
  //       <br />
  //       υψόμετρο: {item.elevation}
  //       <br />
  //       <br />
  //     </li>
  //   );
  //   return (
  //     <>
  //       <>
  //         <strong>Σύνολο φιαλών: </strong> {row.bottles_sum}
  //         <br />
  //         <strong>Aνάλωση: </strong> {row.bestBefore}
  //         <br />
  //         <strong>Αμπελώνες</strong>
  //       </>
  //       <ol>
  //         {vineyards}
  //       </ol>
  //     </>
  //   );
  // }

  // expandRow = {
  //   parentClassName: 'backgroundColorGrey',
  //   className: 'backgroundColorGrey',
  //   onlyOneExpanding: true,
  //   showExpandColumn: false,
  //   //expandByColumnOnly: true,
  //   renderer: row => (
  //     <div>
  //       {this.expandRow_DataFormat(row)}
  //       {' '}
  //       {this.hasDelete && <ModalComponent modalParams={this.deleteModalParams(row)} />}
  //       {' '}
  //       {this.hasEdit && <ModalComponent modalParams={this.createEditModalParams(true, row)} />}
  //     </div>
  //   )
  // }

  applyFilter(event, picker) {
    this.getData();
  };

  clearFilter() {
    $(".DateRangePickerClearIcon").click();
    this.getData();
  };

  clearSelects() {
    this.state.selected = {};
    this.state.source_type_id_history = null;
    this.setState(this.state);
    this.getData();
  }

  handleClickFilter = () => {
    this.setState(this.state);
    this.getData();
  };

  handleSelfClick = (props) => {
    props.searchProps.onSearch(this.searchTerm);
    if (this.searchTermsArr.length > 0) {
      if (this.searchTermsArr[this.searchTermsArr.length - 1] !== this.searchTerm) {
        this.searchTermsArr.push(this.searchTerm);
      }
    }
    else {
      this.searchTermsArr.push(this.searchTerm);
    }
    //console.log(this.searchTermsArr);
  };

  handleGoBack = (props) => {
    var searchTermsArrLength = this.searchTermsArr.length;
    if (searchTermsArrLength > 0) {
      if (searchTermsArrLength > 1) {
        props.searchProps.onSearch(this.searchTermsArr[searchTermsArrLength - 2]);
      }
      else {
        props.searchProps.onSearch("");
      }
      this.searchTermsArr.pop();
    }
  };

  render() {
    //console.log(this.state);
    const { SearchBar, ClearSearchButton } = Search;
    const rowStyle = (row, rowIndex) => {
      const style = {};
      if (row.type == "transport") {
        style.backgroundColor = '#fff27669';
      } else if (row.type == "measurement") {
        style.backgroundColor = 'rgba(0, 0, 0, 0.05)';
      }
      else if (row.type == "action") {
        style.backgroundColor = '#c1e6ff';
      }
      return style;
    };
    const { ExportCSVButton } = CSVExport;
    return (
      <>
        {this.state.tableError && <Alert key={'danger'} variant={'danger'} className="tableErrorMsg">
          {this.state.tableError}
        </Alert>}
        <ToolkitProvider
          keyField="id"
          data={this.state.rows}
          columns={this.state.columns}
          bootstrap4
          search
          exportCSV={{
            fileName: "Winety Dashboard.csv",
            onlyExportFiltered: true,
            exportAll: false,
          }}
        >
          {
            props => (
              <div>
                <div className="pageTitle">Dashboard</div>
                {' '}
                <Button style={{ padding: '0px' }} className="btn-light searchBoxClearBtn" size="sm" onClick={(e) => this.handleGoBack(props)}>
                  <svg style={{ zoom: '2.1' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left-square" viewBox="0 0 16 16" onClick={(e) => this.handleGoBack(props)}>
                    <path fillRule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm11.5 5.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
                  </svg>
                </Button>
                <SearchBar
                  placeholder={Datalanguages["Search"]}
                  {...props.searchProps}
                  className={"searchBox"}
                />
                <ClearSearchButton
                  className="btn-secondary searchBoxClearBtn"
                  text='X'
                  {...props.searchProps}
                />
                <Button className="triggerSearch" size="sm" onClick={(e) => this.handleSelfClick(props)}>
                  triggerSearch
                </Button>

                <ExportCSVButton
                  className="transportCreateBtn btn btn-primary btn-sm"
                  style={{
                    color: "#fff",
                    backgroundColor: "#17a2b8"
                  }}
                  {...props.csvProps}>
                  CSV
                </ExportCSVButton>

                <div className="DateRangePickerContainer">
                  <span className="DateRangePickerTitle">φίλτρο ημερομηνίας Από:</span>
                  <DateTimePicker
                    format={"dd / MM / yyyy HH:mm"}
                    clearIcon={<span className="DateRangePickerClearIcon">X</span>}
                    onChange={date => {
                      this.state.filterStartDate = date;
                      this.setState(this.state);
                    }}
                    value={this.state.filterStartDate}
                  />
                  <span className="DateRangePickerTitle">Έως:</span>
                  <DateTimePicker
                    format={"dd / MM / yyyy HH:mm"}
                    clearIcon={<span className="DateRangePickerClearIcon">X</span>}
                    onChange={date => {
                      this.state.filterEndtDate = date;
                      this.setState(this.state);
                    }}
                    value={this.state.filterEndtDate}
                  />
                  <Button className="DateRangePickerBtn" size="sm" onClick={(e) => this.applyFilter(e)}>
                    ΟΚ
                  </Button>
                  <Button className="DateRangePickerBtn btn-secondary" size="sm" onClick={(e) => this.clearFilter(e)}>
                    ΄Ακυρο
                  </Button>
                </div>
                <br />
                <div className={"containerHistorySelect"}>
                  {this.hasVineyadsSelect && <div className={"historySelect"}>
                    Αμπελώνες
                    <Select
                      key={"vineyardsSelectOptions"}
                      placeholder={'επιλογή αμπελώνα'}
                      options={this.state.vineyardsSelectOptions}
                      value={this.state.source_type_id_history == 2 ? this.state.selected : null}
                      onChange={(newValue) => {
                        // console.log(newValue);
                        this.state.selected = newValue;
                        this.state.source_type_id_history = 2;
                        this.setState(this.state);
                        this.getData();
                      }}
                    />
                  </div>}
                  {this.hasContainersSelect && <div className={"historySelect"}>
                    Δεξαμενές
                    <Select
                      key={"containersSelectOptions"}
                      placeholder={'επιλογή δεξαμενής'}
                      options={this.state.containersSelectOptions}
                      value={this.state.source_type_id_history == 3 ? this.state.selected : null}
                      onChange={(newValue) => {
                        // console.log(newValue);
                        this.state.selected = newValue;
                        this.state.source_type_id_history = 3;
                        this.setState(this.state);
                        this.getData();
                      }}
                    />
                  </div>}
                  {this.hasContainersSelect && <div className={"historySelect"}>
                    Ιστορικό περιεχομένου δεξαμενής
                    <Select
                      key={"fakeBottlingSelectOptions"}
                      placeholder={'επιλογή δεξαμενής'}
                      options={this.state.containersSelectOptions}
                      value={this.state.source_type_id_history == "fakeBottling" ? this.state.selected : null}
                      onChange={(newValue) => {
                        // console.log(newValue);
                        this.state.selected = newValue;
                        this.state.source_type_id_history = "fakeBottling";
                        this.setState(this.state);
                        this.getData();
                      }}
                    />
                  </div>}
                  {this.hasBottlingsSelect && <div className={"historySelect"}>
                    Παρτίδες
                    <Select
                      key={"bottlingsSelectOptions"}
                      placeholder={'επιλογή παρτίδας'}
                      options={this.state.bottlingsSelectOptions}
                      value={this.state.source_type_id_history == "bottling" ? this.state.selected : null}
                      onChange={(newValue) => {
                        // console.log(newValue);
                        this.state.selected = newValue;
                        this.state.source_type_id_history = "bottling";
                        this.setState(this.state);
                        this.getData();
                      }}
                    />
                  </div>}
                  <Button className="btn-secondary historySelectShowAll" size="sm" onClick={(e) => this.clearSelects(e)}>
                    Άκυρο
                  </Button>
                  <div>
                    <p
                      style={{
                        margin: "0px 0px 0px 10px",
                      }}
                      dangerouslySetInnerHTML={{ __html: this.state.extraData_path }}></p>
                  </div>
                </div>
                <div className="displayControleContainer displayDashBoardControleContainer">
                  {this.hasMeasurementsExtraFilters && this.hideVinInfo !== true && <Form.Check type="checkbox" label="μετρήσεις από VinInfo"
                    checked={this.state.displayVinInfo}
                    onChange={(event) => {
                      this.state.displayVinInfo = !this.state.displayVinInfo;
                      this.handleClickFilter();
                    }}
                  />}
                  <Form.Check type="checkbox" label="καιρικές συνθήκες (OpenWeatherMap)"
                    checked={this.state.displayOpenWeatherMapMeasurements}
                    onChange={(event) => {
                      this.state.displayOpenWeatherMapMeasurements = !this.state.displayOpenWeatherMapMeasurements;
                      this.handleClickFilter();
                    }}
                  />
                  {this.hasMeasurementsExtraFilters && this.hideuploadCSV !== true && <Form.Check type="checkbox" label="μετρήσεις από csv Αναλυτή"
                    checked={this.state.displayFromCsvMeasurements}
                    onChange={(event) => {
                      this.state.displayFromCsvMeasurements = !this.state.displayFromCsvMeasurements;
                      this.handleClickFilter();
                    }}
                  />}
                </div>

                <div className="displayControleContainer displayDashBoardControleContainer">
                  <Form.Check type="checkbox" label="ενέργειες"
                    checked={this.state.displayActions}
                    onChange={(event) => {
                      this.state.displayActions = !this.state.displayActions;
                      this.handleClickFilter();
                    }}
                  />
                  <Form.Check type="checkbox" label="μετρήσεις"
                    checked={this.state.displayMeasurements}
                    onChange={(event) => {
                      this.state.displayMeasurements = !this.state.displayMeasurements;
                      this.handleClickFilter();
                    }}
                  />
                  <Form.Check type="checkbox" label="μεταφορές"
                    checked={this.state.displayTransports}
                    onChange={(event) => {
                      this.state.displayTransports = !this.state.displayTransports;
                      this.handleClickFilter();
                    }}
                  />
                </div>
                <BootstrapTable
                  defaultSorted={this.state.defaultSorted}
                  key={this.state.rows}
                  {...props.baseProps}
                  keyField='id'
                  data={this.state.rows}
                  columns={this.state.columns}
                  expandRow={this.hasExpand ? this.expandRow : {}}
                  striped
                  hover
                  bordered={false}
                  noDataIndication={this.indication}
                  bootstrap4
                  pagination={paginationFactory({
                    // showTotal: true,
                    sizePerPage: 15
                  })}
                  rowStyle={rowStyle}
                />
              </div>
            )
          }
        </ToolkitProvider>
      </>
    );
  }
}
