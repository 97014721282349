/* eslint-disable */
import React from 'react';
import configData from "../config/config.js";
import { Form } from 'react-bootstrap';
import axios from 'axios';
import ModalComponent from '../components/ModalComponent';
//import TableComponent from './TableComponent';
import $ from 'jquery'

import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
//import { Table } from 'react-bootstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import authHeader from '../services/auth-header';
import AuthService from "../services/auth.service";
import { Alert } from 'react-bootstrap';

import '../App.css';

import Datalanguages from "../languages/el.json"

export default class Wines extends React.Component {

  oneItem = "wine"
  manyItems = "wines"
  createNewTheTitle = "Δημιουργία τελικού προϊόντος"
  editTheTitle = "Επεξεργασία τελικού προϊόντος"
  deleteTheTitle = "Διαγραφή τελικού προϊόντος"
  pageTitle = "Τελικό προϊόν"


  user = AuthService.getCurrentUser();
  //isProduser = this.user && ["ROLE_PRODUCER"].some(substring => this.user.roles.includes(substring));
  hasExpand = true;
  hasDelete = true;
  hasEdit = true;
  hasCreate = true;

  state = {
    rows: [],
    getDataPath: configData.API_URL + 'get' + "WinesForWeb",
    createPath: configData.API_URL + 'new' + this.oneItem,
    //createPath: configData.API_URL + 'testNew' + this.oneItem,
    editPath: configData.API_URL + 'edit' + this.oneItem,
    deletePath: configData.API_URL + 'delete' + this.oneItem,
    defaultSorted: [{
      dataField: 'name', // if dataField is not match to any column you defined, it will be ignored.
      order: 'asc' // desc or asc
    }],
    columns: [
      {
        dataField: 'name',
        text: "ονομασία",
        sort: true,
        align: 'center',
        headerAlign: 'center'
      },
      {
        dataField: 'category',
        text: "κατηγορία",
        sort: true,
        align: 'center',
        headerAlign: 'center'
      },
      {
        dataField: 'POP_ranking',
        text: "κατάταξη",
        sort: true,
        align: 'center',
        headerAlign: 'center'
      },
      {
        dataField: 'aging_months',
        text: "μήνες παλαίωσης",
        sort: true,
        align: 'center',
        headerAlign: 'center'
      },
      {
        dataField: 'months_consumption',
        text: "μήνες ανάλωσης",
        sort: true,
        align: 'center',
        headerAlign: 'center'
      },
      {
        dataField: 'bottle_volume',
        text: "χωρητικότητα",
        sort: true,
        align: 'center',
        headerAlign: 'center',
        formatter: (cell, row) => {
          return (
            <span>{cell} ml</span>
          );
        },
      },
      {
        dataField: 'numberOfShownVineyards',
        text: "πλήθος αμπελώνων",
        sort: true,
        align: 'center',
        headerAlign: 'center'
      },
      {
        dataField: 'temperature_consumption',
        text: "θερμοκρασία κατανάλωσης",
        sort: true,
        align: 'center',
        headerAlign: 'center'
      },
      {
        dataField: 'temperature_storage',
        text: "θερμοκρασία αποθήκευσης",
        sort: true,
        align: 'center',
        headerAlign: 'center'
      },
      // {
      //   dataField: 'chemical_analysis',
      //   text: "χημική ανάλυση",
      //   sort: true,
      //   align: 'center',
      //   headerAlign: 'center'
      // },
      // {
      //   dataField: 'description',
      //   text: "περιγραφή",
      //   sort: true,
      //   align: 'center',
      //   headerAlign: 'center'
      // },
      // {
      //   dataField: 'organoleptic_characteristics',
      //   text: "οργανοληπτικά χαρακτηριστικά",
      //   sort: true,
      //   align: 'center',
      //   headerAlign: 'center'
      // },
      // {
      //   dataField: 'suggested_food',
      //   text: "προτεινόμενα φαγητά",
      //   sort: true,
      //   align: 'center',
      //   headerAlign: 'center'
      // },
      // {
      //   dataField: 'vinification_method',
      //   text: "διαδικασία οινοποίησης",
      //   sort: true,
      //   align: 'center',
      //   headerAlign: 'center'
      // }
    ]
  }

  indication() {
    return Datalanguages["Table is Empty"];
  }

  componentDidMount() {
    AuthService.checkUserIsLoggdeIn(this);
    this.getData();
  }

  getData() {
    var _this = this;
    axios.get(_this.state.getDataPath, {
      headers: authHeader(),
    })
      .then(res => {
        _this.state.rows = res.data
        _this.setState(_this.state);
      })
      .catch(error => {
        console.log(error);
        this.setState({
          tableError: error
        });
      })
  }

  createEditModalParams(editMode, row) {
    var _this = this;

    return {
      title: editMode ? _this.editTheTitle : _this.createNewTheTitle,
      openBtn: {
        label: editMode ? Datalanguages["EDIT"] : " + ",
        variant: editMode ? "success" : "primary",
        class: editMode ? "modalBtn" : "createBtn",
      },
      okBtn: {
        label: editMode ? Datalanguages["SAVE"] : Datalanguages["CREATE"],
        variant: editMode ? "success" : "primary"
      },
      body:
        <div>
          <Form.Group controlId="createEditModal_wine_name">
            <Form.Label>{"ονομασία τελικού προϊόντος"}</Form.Label>
            <Form.Control as="textarea" rows="1" defaultValue={row && row.name} />
          </Form.Group>
          <Form.Group controlId="createEditModal_wine_category">
            <Form.Label>{"κατηγορία κρασιού"}</Form.Label>
            <Form.Control as="textarea" rows="1" defaultValue={row && row.category} />
          </Form.Group>
          <Form.Group controlId="createEditModal_wine_POP_ranking">
            <Form.Label>{"κατάταξη"}</Form.Label>
            <Form.Control as="textarea" rows="1" defaultValue={row && row.POP_ranking} />
          </Form.Group>
          <Form.Group controlId="createEditModal_wine_QR_Code">
            <Form.Label>{"QR code"}</Form.Label>
            <Form.Control as="textarea" rows="1" defaultValue={row && row.POP_ranking} />
          </Form.Group>
          <Form.Group controlId="createEditModal_wine_Barcode">
            <Form.Label>{"barcode"}</Form.Label>
            <Form.Control as="textarea" rows="1" defaultValue={row && row.POP_ranking} />
          </Form.Group>
          <Form.Group controlId="createEditModal_wine_aging_months">
            <Form.Label>{"μήνες παλαίωσης"}</Form.Label>
            <Form.Control type="number" min="0" defaultValue={row && row.aging_months || 0} />
          </Form.Group>
          <Form.Group controlId="createEditModal_wine_months_consumption">
            <Form.Label>{"μήνες ανάλωσης"}</Form.Label>
            <Form.Control type="number" min="0" defaultValue={row && row.months_consumption || 0} />
          </Form.Group>
          <Form.Group controlId="createEditModal_wine_bottle_volume">
            <Form.Label>{"χωρητικότητα φιάλης σε ml"}</Form.Label>
            <Form.Control type="number" min="0" defaultValue={row && row.bottle_volume || 0} />
          </Form.Group>
          <Form.Group controlId="createEditModal_wine_numberOfShownVineyards">
            <Form.Label>{"πλήθος εμφανιζόμενων αμπελώνων"}</Form.Label>
            <Form.Control type="number" min="1" defaultValue={row && row.numberOfShownVineyards || 1} />
          </Form.Group>
          <Form.Group controlId="createEditModal_wine_temperature_consumption">
            <Form.Label>{"θερμοκρασία κατανάλωσης"}</Form.Label>
            <Form.Control type="number" min="0" defaultValue={row && row.temperature_consumption || 0} />
          </Form.Group>
          <Form.Group controlId="createEditModal_wine_temperature_storage">
            <Form.Label>{"θερμοκρασία αποθήκευσης"}</Form.Label>
            <Form.Control type="number" min="0" defaultValue={row && row.temperature_storage || 0} />
          </Form.Group>
          <Form.Group controlId="createEditModal_wine_description">
            <Form.Label>{"περιγραφή"}</Form.Label>
            <Form.Control as="textarea" rows="3" defaultValue={row && row.description} />
          </Form.Group>
          <Form.Group controlId="createEditModal_wine_suggested_food">
            <Form.Label>{"προτεινόμενα φαγητά"}</Form.Label>
            <Form.Control as="textarea" rows="3" defaultValue={row && row.suggested_food} />
          </Form.Group>          
          {/* <Form.Group controlId="createEditModal_wine_chemical_analysis">
            <Form.Label>{"χημική ανάλυση"}</Form.Label>
            <Form.Control as="textarea" rows="3" defaultValue={row && row.chemical_analysis} />
          </Form.Group>
          <Form.Group controlId="createEditModal_wine_organoleptic_characteristics">
            <Form.Label>{"οργανοληπτικά χαρακτηριστικά"}</Form.Label>
            <Form.Control as="textarea" rows="3" defaultValue={row && row.organoleptic_characteristics} />
          </Form.Group>
          <Form.Group controlId="createEditModal_wine_vinification_method">
            <Form.Label>{"περιγραφή διαδικασίας οινοποίησης"}</Form.Label>
            <Form.Control as="textarea" rows="3" defaultValue={row && row.vinification_method} />
          </Form.Group> */}
        </div>
      ,
      handleOK: function () {
        return editMode === true ? _this.edit(row) : _this.create();
      },
      afterRender: function () {
        if (editMode === true) {
          _this.setInitialValues(row);
        }
      },
    };
  }

  setInitialValues(row) {
    //console.log(row);
  }

  create() {
    return axios.post(
      this.state.createPath,
      {
        name: $("#createEditModal_wine_name").val(),
        category: $("#createEditModal_wine_category").val(),
        POP_ranking: $("#createEditModal_wine_POP_ranking").val(),
        aging_months: $("#createEditModal_wine_aging_months").val(),
        months_consumption: $("#createEditModal_wine_months_consumption").val(),
        bottle_volume: $("#createEditModal_wine_bottle_volume").val(),
        numberOfShownVineyards: $("#createEditModal_wine_numberOfShownVineyards").val(),
        temperature_consumption: $("#createEditModal_wine_temperature_consumption").val(),
        temperature_storage: $("#createEditModal_wine_temperature_storage").val(),
        chemical_analysis: $("#createEditModal_wine_chemical_analysis").val(),
        description: $("#createEditModal_wine_description").val(),
        organoleptic_characteristics: $("#createEditModal_wine_organoleptic_characteristics").val(),
        suggested_food: $("#createEditModal_wine_suggested_food").val(),
        vinification_method: $("#createEditModal_wine_vinification_method").val(),
        QR_Code: $("#createEditModal_wine_QR_Code").val(),
        Barcode: $("#createEditModal_wine_Barcode").val()
      },
      { headers: authHeader() }
    ).then(res => {
      // console.log(res);
      this.state.rows = res.data
      this.setState(this.state);
      return res;
    });
  }

  edit(row) {
    return axios.put(
      this.state.editPath,
      {
        id: row.id,
        name: $("#createEditModal_wine_name").val(),
        category: $("#createEditModal_wine_category").val(),
        POP_ranking: $("#createEditModal_wine_POP_ranking").val(),
        aging_months: $("#createEditModal_wine_aging_months").val(),
        months_consumption: $("#createEditModal_wine_months_consumption").val(),
        bottle_volume: $("#createEditModal_wine_bottle_volume").val(),
        numberOfShownVineyards: $("#createEditModal_wine_numberOfShownVineyards").val(),
        temperature_consumption: $("#createEditModal_wine_temperature_consumption").val(),
        temperature_storage: $("#createEditModal_wine_temperature_storage").val(),
        chemical_analysis: $("#createEditModal_wine_chemical_analysis").val(),
        description: $("#createEditModal_wine_description").val(),
        organoleptic_characteristics: $("#createEditModal_wine_organoleptic_characteristics").val(),
        suggested_food: $("#createEditModal_wine_suggested_food").val(),
        vinification_method: $("#createEditModal_wine_vinification_method").val(),
        QR_Code: $("#createEditModal_wine_QR_Code").val(),
        Barcode: $("#createEditModal_wine_Barcode").val()
      },
      { headers: authHeader() }
    ).then(res => {
      this.state.rows = res.data
      this.setState(this.state);
      return res;
    });
  }

  deleteModalParams(row) {
    var _this = this;
    return {
      title: _this.deleteTheTitle,
      openBtn: {
        label: Datalanguages["DELETE"],
        variant: "danger",
        class: "modalBtn"
      },
      okBtn: {
        label: Datalanguages["DELETE"],
        variant: "danger"
      },
      body: "Θελετε να διαγράψετε το τελικό προϊόν " + "\"" + row.name + "\";",
      handleOK: function () {
        return _this.delete(row.id);
      },
      afterRender: function () { },
    };
  }

  delete(ID) {
    return axios.delete(
      this.state.deletePath + "/" + ID,
      { headers: authHeader() }
    ).then(res => {
      // console.log(res);
      this.state.rows = res.data
      this.setState(this.state);
      return res;
    })
  }

  expandRow = {
    parentClassName: 'backgroundColorGrey',
    className: 'backgroundColorGrey',
    onlyOneExpanding: true,
    showExpandColumn: false,
    //expandByColumnOnly: true,
    renderer: row => (
      <div>
        <table>
          <tbody>
            <tr>
              <th>QR code</th>
              <td>{row.QR_Code}</td>
            </tr>
            <tr>
              <th>barcode</th>
              <td>{row.Barcode}</td>
            </tr>
            <tr>
              <th>περιγραφή</th>
              <td>{row.description}</td>
            </tr>
            {/* <tr>
              <th>οργανοληπτικά χαρακτηριστικά</th>
              <td>{row.organoleptic_characteristics}</td>
            </tr> */}
            <tr>
              <th>προτεινόμενα φαγητά</th>
              <td>{row.suggested_food}</td>
            </tr>
            {/* <tr>
              <th>διαδικασία οινοποίησης</th>
              <td>{row.vinification_method}</td>
            </tr> */}
          </tbody>
        </table>
        <br />
        {' '}
        <ModalComponent modalParams={this.deleteModalParams(row)} />
        {' '}
        <ModalComponent modalParams={this.createEditModalParams(true, row)} />
      </div>
    )
  }

  render() {
    //console.log(this.state);
    const { SearchBar, ClearSearchButton } = Search;
    return (<>
      {/* <div onClick={(e) => this.setState({ error: null })}></div> */}
      {this.state.tableError && <Alert key={'danger'} variant={'danger'} className="tableErrorMsg">
        {this.state.tableError}
      </Alert>}
      <ToolkitProvider
        keyField="id"
        data={this.state.rows}
        columns={this.state.columns}
        bootstrap4
        search
      >
        {
          props => (
            <div>
              <div className="pageTitle">{this.pageTitle}</div>
              {this.hasCreate && <ModalComponent modalParams={this.createEditModalParams()} />}
              {' '}
              <SearchBar
                placeholder={Datalanguages["Search"]}
                {...props.searchProps}
                  className={"searchBox"}
              />
                <ClearSearchButton
                  className="btn-secondary searchBoxClearBtn"
                  text='X'
                  {...props.searchProps}
                />
              <BootstrapTable
                defaultSorted={this.state.defaultSorted}
                key={this.state.rows}
                {...props.baseProps}
                keyField='id'
                data={this.state.rows}
                columns={this.state.columns}
                expandRow={this.hasExpand ? this.expandRow : {}}
                striped
                hover
                bordered={false}
                noDataIndication={this.indication}
                bootstrap4
                pagination={paginationFactory()}
              />
            </div>
          )
        }
      </ToolkitProvider>
    </>
    );
  }
}
