/* eslint-disable */
import React from 'react';
import configData from "../config/config.js";
import { Form } from 'react-bootstrap';
import axios from 'axios';
import ModalComponent from '../components/ModalComponent';
import MapComponent from '../components/MapComponent';
//import TableComponent from './TableComponent';
import $ from 'jquery';

import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
//import { Table } from 'react-bootstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import authHeader from '../services/auth-header';
import AuthService from "../services/auth.service";
import { Alert } from 'react-bootstrap';
import Select from 'react-select';

import '../App.css';

import Datalanguages from "../languages/el.json";

export default class Vineyards extends React.Component {

  oneItem = "vineyard"
  manyItems = "vineyards"
  createNewTheTitle = Datalanguages["Create new vineyard"]
  editTheTitle = Datalanguages["Edit vineyard"]
  deleteTheTitle = Datalanguages["Delete vineyard"]

  user = AuthService.getCurrentUser();

  hideTH2E = this.user && this.user.company && (this.user.company.TH2E == 1) ? false : true;
  isAgronomistORproducer = this.user && ["ROLE_PRODUCER", "ROLE_AGRONOMIST"].some(substring => this.user.roles.includes(substring));
  hasExpand = true;
  hasDelete = !this.isAgronomistORproducer;
  hasEdit = true;
  hasCreate = !this.isAgronomistORproducer;

  state = {
    rows: [],
    Producers: [],
    Varieties: [],
    getDataPath: configData.API_URL + 'get' + this.manyItems,
    createPath: configData.API_URL + 'new' + this.oneItem,
    //createPath: configData.API_URL + 'testNew' + this.oneItem,
    editPath: configData.API_URL + 'edit' + this.oneItem,
    deletePath: configData.API_URL + 'delete' + this.oneItem,
    defaultSorted: [{
      dataField: 'vineyard_code', // if dataField is not match to any column you defined, it will be ignored.
      order: 'asc' // desc or asc
    }],
    columns: [
      {
        dataField: 'vineyard_code',
        text: Datalanguages["vineyard code"],
        sort: true,
        align: 'center',
        headerAlign: 'center'
      },
      {
        dataField: 'partition',
        text: Datalanguages["partition"],
        sort: true,
        align: 'center',
        headerAlign: 'center'
      },
      {
        dataField: 'variety.name',
        text: Datalanguages["variety"],
        sort: true,
        align: 'center',
        headerAlign: 'center',
      },
      {
        dataField: 'producer.last_name',
        text: "παραγωγός",
        sort: true,
        align: 'center',
        headerAlign: 'center',
        formatter: (cell, row) => {
          var tempproducer = "-";
          if (row.producer != undefined && row.producer.last_name != undefined && row.producer.first_name != undefined) {
            tempproducer = row.producer.last_name + " " + row.producer.first_name + " " + row.producer.producer.code;
          }
          return (
            <span>{tempproducer}</span>
          );
        }
      },
      {
        dataField: 'name_or_address',
        text: Datalanguages["name or address"],
        sort: true,
        align: 'center',
        headerAlign: 'center'
      },
      {
        dataField: 'size',
        text: "εμβαδό",
        sort: true,
        align: 'center',
        headerAlign: 'center',
        formatter: (cell, row) => {
          return (
            <span>{cell} str</span>
          );
        }
      },
      {
        hidden: this.hideTH2E,
        dataField: 'TH2E_mac',
        text: 'TH2E_mac',
        sort: true,
        align: 'center',
        headerAlign: 'center'
      },
      // {
      //   dataField: 'location',
      //   text: Datalanguages["Coordinates"],
      //   sort: true,
      //   align: 'center',
      //   headerAlign: 'center'
      // },
      // {
      //   dataField: 'roots',
      //   text: Datalanguages["roots"],
      //   sort: true,
      //   align: 'center',
      //   headerAlign: 'center'
      // },
      // {
      //   dataField: 'elevation',
      //   text: Datalanguages["elevation"],
      //   sort: true,
      //   align: 'center',
      //   headerAlign: 'center',
      //   formatter: (cell, row) => {
      //     return (
      //       <span>{cell} m</span>
      //     );
      //   }
      // },
      // {
      //   dataField: 'orientation',
      //   text: "προσανατολισμός",
      //   sort: true,
      //   align: 'center',
      //   headerAlign: 'center'
      // },
      // {
      //   dataField: 'slope',
      //   text: "κλίση",
      //   sort: true,
      //   align: 'center',
      //   headerAlign: 'center'
      // },
      // {
      //   dataField: 'soil',
      //   text: "εδάφος",
      //   sort: true,
      //   align: 'center',
      //   headerAlign: 'center'
      // },
      // {
      //   dataField: 'performance',
      //   text: Datalanguages["performance"],
      //   sort: true,
      //   align: 'center',
      //   headerAlign: 'center',
      //   formatter: (cell, row) => {
      //     return (
      //       <span>{cell} kg/str</span>
      //     );
      //   }
      // },
    ]
  }

  createEditModal_vineyard_producer_id = undefined;
  createEditModal_vineyard_varieties_id = undefined;

  indication() {
    return Datalanguages["Table is Empty"];
  }

  componentDidMount() {
    AuthService.checkUserIsLoggdeIn(this);
    this.getData();
  }

  getData() {
    var _this = this;
    axios.get(_this.state.getDataPath, {
      headers: authHeader(),
    })
      .then(res => {
        _this.state.rows = res.data.Vineyards == undefined ? [] : res.data.Vineyards;
        _this.state.Producers = res.data.Producers == undefined ? [] : res.data.Producers;
        _this.state.Varieties = res.data.Varieties == undefined ? [] : res.data.Varieties;
        _this.setState(_this.state);
      })
      .catch(error => {
        console.log(error);
        this.setState({
          tableError: error
        });
      })
  }

  createEditModalParams(editMode, row) {
    var _this = this;
    //console.log(this.state);
    const optionsProducers = this.state.Producers.map(opt => ({ label: opt.code + " " + opt.user.first_name + " " + opt.user.last_name, value: opt.user.id }));
    const optionsVarieties = this.state.Varieties.map(opt => ({ label: opt.name, value: opt.id }));

    return {
      title: editMode ? _this.editTheTitle : _this.createNewTheTitle,
      openBtn: {
        label: editMode ? Datalanguages["EDIT"] : " + ",
        variant: editMode ? "success" : "primary",
        class: editMode ? "modalBtn" : "createBtn",
      },
      okBtn: {
        label: editMode ? Datalanguages["SAVE"] : Datalanguages["CREATE"],
        variant: editMode ? "success" : "primary"
      },
      body:
        <div>
          <Form.Group controlId="createEditModal_vineyard_vineyard_code">
            <Form.Label>{Datalanguages["vineyard code"]}</Form.Label>
            <Form.Control type="text" defaultValue={row && row.vineyard_code} />
          </Form.Group>
          <Form.Group controlId="createEditModal_vineyard_partition">
            <Form.Label>{Datalanguages["partition"]}</Form.Label>
            <Form.Control type="text" defaultValue={row && row.partition} />
          </Form.Group>
          <Form.Label>{Datalanguages["variety"]}</Form.Label>
          <Select id="createEditModal_vineyard_varieties_id"
            placeholder={'επιλογή...'}
            options={optionsVarieties}
            defaultValue={editMode ? optionsVarieties.find(option => option.value === row.varieties_id) : undefined}
            onChange={(newValue) => {
              this.createEditModal_vineyard_varieties_id = newValue.value;
            }}
          />
          <br />
          {this.hideTH2E !== true &&
            <Form.Group controlId="createEditModal_vineyard_TH2E_mac">
              <Form.Label>{"TH2E_mac"}</Form.Label>
              <Form.Control type="text" defaultValue={row && row.TH2E_mac} />
            </Form.Group>}
          <Form.Group controlId="createEditModal_vineyard_name_or_address">
            <Form.Label>{Datalanguages["name or address"]}</Form.Label>
            <Form.Control type="text" defaultValue={row && row.name_or_address} />
          </Form.Group>
          <Form.Group controlId="createEditModal_vineyard_location">
            <Form.Label>{Datalanguages["Coordinates"]}</Form.Label>
            <Form.Control type="text" defaultValue={row && row.location} pattern="" />
            <div className="mapContainerInModal">
              {/* 20.8522784,39.6639818 -> ioannina */}
              <MapComponent location={row && this.checkCords(row.location) ? row.location : null}
                singleclick={true} target={"mapContainerInModal"} />
              <div id={"mapContainerInModal"} className="map">
              </div>
            </div>
          </Form.Group>
          <Form.Group controlId="createEditModal_vineyard_size">
            <Form.Label>εμβαδό (στρέμματα)</Form.Label>
            <Form.Control type="number" min="0" defaultValue={row && row.size || 0} />
          </Form.Group>
          <Form.Group controlId="createEditModal_vineyard_roots">
            <Form.Label>{Datalanguages["roots"]}</Form.Label>
            <Form.Control type="number" min="0" defaultValue={row && row.roots || 0} />
          </Form.Group>
          <Form.Group controlId="createEditModal_vineyard_elevation">
            <Form.Label>{Datalanguages["elevation"]}</Form.Label>
            <Form.Control type="number" min="0" defaultValue={row && row.elevation || 0} />
          </Form.Group>
          <Form.Group controlId="orientation">
            <Form.Label>προσανατολισμός</Form.Label>
            <Form.Control type="text" defaultValue={row && row.orientation} />
          </Form.Group>
          <Form.Group controlId="slope">
            <Form.Label>κλίση (%)</Form.Label>
            <Form.Control type="number" min="0" defaultValue={row && row.slope || 0} />
          </Form.Group>
          <Form.Group controlId="soil">
            <Form.Label>έδαφος</Form.Label>
            <Form.Control as="textarea" rows="3" defaultValue={row && row.soil} />
          </Form.Group>
          <Form.Group controlId="meteorological_conditions">
            <Form.Label>μετεωρολογικές συνθήκες</Form.Label>
            <Form.Control as="textarea" rows="3" defaultValue={row && row.meteorological_conditions} />
          </Form.Group>
          <Form.Group controlId="createEditModal_vineyard_performance">
            <Form.Label>{Datalanguages["performance"]}</Form.Label>
            <Form.Control type="number" min="0" defaultValue={row && row.performance || 0} />
          </Form.Group>
        </div>
      ,
      handleOK: function () {
        return editMode === true ? _this.edit(row) : _this.create();
      },
      afterRender: function () {
        if (editMode === true) {
          _this.setInitialValues(row);
        }
      },
    };
  }

  setInitialValues(row) {
    //console.log(row);
    this.createEditModal_vineyard_producer_id = row.producer && row.producer.id;
    this.createEditModal_vineyard_varieties_id = row.varieties_id;
  }

  isLatitude(maybeLat) {
    var latF = parseFloat(maybeLat)
    if (isNaN(latF)) return false
    return (latF >= -90 && latF <= 90)
  }

  isLongitude(maybeLon) {
    var lonF = parseFloat(maybeLon)
    if (isNaN(lonF)) return false
    return lonF >= -180 && lonF <= 180
  }

  checkCords(Cords) {
    if (Cords == undefined) return false
    if (!(/^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/
      .test(Cords))) return false
    var maybeLon = Cords.split(',')[0];
    var maybeLat = Cords.split(',')[1];
    return this.isLatitude(maybeLat) && this.isLongitude(maybeLon) ? true : false;
  }

  create() {
    if (!this.checkCords($("#createEditModal_vineyard_location").val())) {
      return Promise.reject('Η επιλογή "συντεταγμένες" δεν είναι σωστή.');
    }
    return axios.post(
      this.state.createPath,
      {
        vineyard_code: $("#createEditModal_vineyard_vineyard_code").val(),
        partition: $("#createEditModal_vineyard_partition").val(),
        userId: this.createEditModal_vineyard_producer_id,
        varieties_id: this.createEditModal_vineyard_varieties_id,
        name_or_address: $("#createEditModal_vineyard_name_or_address").val(),
        location: $("#createEditModal_vineyard_location").val(),
        size: $("#createEditModal_vineyard_size").val(),
        roots: $("#createEditModal_vineyard_roots").val(),
        elevation: $("#createEditModal_vineyard_elevation").val(),
        orientation: $("#orientation").val(),
        slope: $("#slope").val(),
        soil: $("#soil").val(),
        meteorological_conditions: $("#meteorological_conditions").val(),
        performance: $("#createEditModal_vineyard_performance").val(),
        TH2E_mac: $("#createEditModal_vineyard_TH2E_mac").val()
      },
      { headers: authHeader() }
    ).then(res => {
      //console.log(res);
      if (res.status === 200) {
        this.setState({
          rows: []
        });
        this.setState({
          rows: res.data
        });
      }
      return res;
    });
  }

  edit(row) {
    if (!this.checkCords($("#createEditModal_vineyard_location").val())) {
      return Promise.reject('Η επιλογή "συντεταγμένες" δεν είναι σωστή.');
    }
    return axios.put(
      this.state.editPath,
      {
        id: row.id,
        vineyard_code: $("#createEditModal_vineyard_vineyard_code").val(),
        partition: $("#createEditModal_vineyard_partition").val(),
        userId: this.createEditModal_vineyard_producer_id,
        varieties_id: this.createEditModal_vineyard_varieties_id,
        name_or_address: $("#createEditModal_vineyard_name_or_address").val(),
        location: $("#createEditModal_vineyard_location").val(),
        size: $("#createEditModal_vineyard_size").val(),
        roots: $("#createEditModal_vineyard_roots").val(),
        elevation: $("#createEditModal_vineyard_elevation").val(),
        orientation: $("#orientation").val(),
        slope: $("#slope").val(),
        soil: $("#soil").val(),
        meteorological_conditions: $("#meteorological_conditions").val(),
        performance: $("#createEditModal_vineyard_performance").val(),
        TH2E_mac: $("#createEditModal_vineyard_TH2E_mac").val()
      },
      { headers: authHeader() }
    ).then(res => {
      if (res.status === 200) {
        this.setState({
          rows: []
        });
        this.setState({
          rows: res.data
        });
      }
      return res;
    });
  }

  deleteModalParams(row) {
    var _this = this;
    return {
      title: _this.deleteTheTitle,
      openBtn: {
        label: Datalanguages["DELETE"],
        variant: "danger",
        class: "modalBtn"
      },
      okBtn: {
        label: Datalanguages["DELETE"],
        variant: "danger"
      },
      body: "Θέλετε να διαγράψετε τον αμπελώνα: " + row.vineyard_code + " " + row.partition + ";",
      handleOK: function () {
        return _this.delete(row.id);
      },
      afterRender: function () { },
    };
  }

  delete(ID) {
    return axios.delete(
      this.state.deletePath + "/" + ID,
      { headers: authHeader() }
    ).then(res => {
      // console.log(res);
      if (res.status === 200) {
        var tempRows = this.state.rows;
        var pos = tempRows.map(function (e) { return e.id; }).indexOf(ID);
        tempRows.splice(pos, 1);
        this.setState({
          rows: tempRows
        });
      }
      return res;
    })
  }

  handleOnExpand = (row, isExpand, rowIndex, e) => {
    //var _this = this;
    if (isExpand) {
      if (this.checkCords(row.location)) {
        row.lon = row.location.split(',')[0];
        row.lat = row.location.split(',')[1];
        return axios.post(
          configData.API_URL + 'getVineyard_Weather',
          {
            vineyard: row
          },
          { headers: authHeader() }
        )
          .then(res => {
            // console.log(res);
            //var weatherText = JSON.stringify(res.data, null, '\t');
            var weatherText = res.data;
            this.setState({
              vineyard_Weather: weatherText
            });
          })
          .catch(error => {
            console.log(error);
          });
      }
    }
  }

  expandRow = {
    parentClassName: 'backgroundColorGrey',
    className: 'backgroundColorGrey',
    onlyOneExpanding: true,
    showExpandColumn: false,
    onExpand: this.handleOnExpand,
    //expandByColumnOnly: true,
    renderer: row => (
      <div>
        <table className="vineyardsExpandRowTable">
          <tbody>
            <tr>
              <th>συντεταγμένες</th>
              <td>{row.location}</td>
            </tr>
            <tr>
              <th>πρέμνα</th>
              <td>{row.roots}</td>
            </tr>
            <tr>
              <th>υψόμετρο</th>
              <td>{row.elevation} m</td>
            </tr>
            <tr>
              <th>προσανατολισμός</th>
              <td>{row.orientation}</td>
            </tr>
            <tr>
              <th>κλίση</th>
              <td>{row.slope} %</td>
            </tr>
            <tr>
              <th>εδάφος</th>
              <td>{row.soil}</td>
            </tr>
            <tr>
              <th>μετεωρολογικές συνθήκες</th>
              <td>{row.meteorological_conditions}</td>
            </tr>
            {this.checkCords(row.location) && <tr>
              <th>πρόσφατες καιρικές συνθήκες</th>
              <td>
                <pre>
                  θερμοκρασία: {this.state.vineyard_Weather && this.state.vineyard_Weather.main && this.state.vineyard_Weather.main.temp}°C
                  <br />
                  μέγιστη θερμοκρασία: {this.state.vineyard_Weather && this.state.vineyard_Weather.main && this.state.vineyard_Weather.main.temp_max}°C
                  <br />
                  ελάχιστη θερμοκρασία: {this.state.vineyard_Weather && this.state.vineyard_Weather.main && this.state.vineyard_Weather.main.temp_min}°C
                  <br />
                  υγρασία: {this.state.vineyard_Weather && this.state.vineyard_Weather.main && this.state.vineyard_Weather.main.humidity}%
                  <br />
                  πίεση: {this.state.vineyard_Weather && this.state.vineyard_Weather.main && this.state.vineyard_Weather.main.pressure}hPa
                  <br />
                  ταχύτητα ανέμου: {this.state.vineyard_Weather && this.state.vineyard_Weather.wind && this.state.vineyard_Weather.wind.speed}km/h
                  <br />
                  διεύθυνση ανέμου: {this.state.vineyard_Weather && this.state.vineyard_Weather.wind && this.state.vineyard_Weather.wind.deg}°
                </pre>
              </td>
            </tr>}
            <tr>
              <th>μέγιστη απόδοση</th>
              <td>{row.performance} kg/str</td>
            </tr>
          </tbody>
        </table>
        {this.checkCords(row.location) && <div className="mapContainer">
          <MapComponent location={row && this.checkCords(row.location) ? row.location : "20.8522784,39.6639818"}
            singleclick={false} target={"mapContainer"} />
          <div id={"mapContainer"} className="map">
          </div>
        </div>}
        <br />
        {' '}
        {this.hasDelete && <ModalComponent modalParams={this.deleteModalParams(row)} />}
        {' '}
        {this.hasEdit && <ModalComponent modalParams={this.createEditModalParams(true, row)} />}
      </div>
    )
  }

  render() {
    //console.log(this);
    const { SearchBar, ClearSearchButton } = Search;
    return (
      <>
        {/* <div onClick={(e) => this.setState({ error: null })}></div> */}
        {this.state.tableError && <Alert key={'danger'} variant={'danger'} className="tableErrorMsg">
          {this.state.tableError}
        </Alert>}
        <ToolkitProvider
          keyField="id"
          data={this.state.rows}
          columns={this.state.columns}
          bootstrap4
          search
        >
          {
            props => (
              <div>
                <div className="pageTitle">{Datalanguages["Vineyards"]}</div>
                {this.hasCreate && <ModalComponent modalParams={this.createEditModalParams(false)} />}
                {' '}
                <SearchBar
                  placeholder={Datalanguages["Search"]}
                  {...props.searchProps}
                  className={"searchBox"}
                />
                <ClearSearchButton
                  className="btn-secondary searchBoxClearBtn"
                  text='X'
                  {...props.searchProps}
                />
                <BootstrapTable
                  defaultSorted={this.state.defaultSorted}
                  key={this.state.rows}
                  {...props.baseProps}
                  keyField='id'
                  data={this.state.rows}
                  columns={this.state.columns}
                  expandRow={this.hasExpand ? this.expandRow : {}}
                  striped
                  hover
                  bordered={false}
                  noDataIndication={this.indication}
                  bootstrap4
                  pagination={paginationFactory()}
                />
              </div>
            )
          }
        </ToolkitProvider>
      </>
    );
  }
}
