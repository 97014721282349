/* eslint-disable */
import { Modal, Button } from 'react-bootstrap';
import React from 'react';
import Datalanguages from "../languages/el.json"


export default class ErrorModalComponent extends React.Component {

  state = {
    show: false
  }

  handleShow() {
    this.setState({
      show: true
    });
  }

  handleClose() {
    this.setState({
      show: false
    });
  }

  render() {
    return (
      <>
        <Modal
          className={"zoom"}
          show={this.state.show}
          onHide={(e) => this.handleClose(e)}
          backdrop="static"
          keyboard={false}
          animation={false}
          centered
        >
          <Modal.Header style={{ color: "red" }} >
            <Modal.Title>Σφάλμα</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.props.msg}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={(e) => this.handleClose(e)}>
              ΟΚ
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}