/* eslint-disable */
import React from 'react';
import configData from "../config/config.js";
import { Form } from 'react-bootstrap';
import axios from 'axios';
import ModalComponent from '../components/ModalComponent';
//import TableComponent from './TableComponent';
import $ from 'jquery'

import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
//import { Table } from 'react-bootstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import authHeader from '../services/auth-header';
import AuthService from "../services/auth.service";
import { Alert } from 'react-bootstrap';

import '../App.css';

import Datalanguages from "../languages/el.json"

export default class Companies extends React.Component {

  oneItem = "company"
  manyItems = "companies"
  createNewTheTitle = "Δημιουργία εταιρείας"
  editTheTitle = "Επεξεργασία εταιρείας"
  deleteTheTitle = "Διαγραφή εταιρείας"
  pageTitle = "Εταιρείες"

  user = AuthService.getCurrentUser();
  //isProduser = this.user && ["ROLE_PRODUCER"].some(substring => this.user.roles.includes(substring));
  hasExpand = true;
  hasDelete = true;
  hasEdit = true;
  hasCreate = true;

  state = {
    rows: [],
    getDataPath: configData.API_URL + 'get' + this.manyItems,
    createPath: configData.API_URL + 'new' + this.oneItem,
    //createPath: configData.API_URL + 'testNew' + this.oneItem,
    editPath: configData.API_URL + 'edit' + this.oneItem,
    deletePath: configData.API_URL + 'delete' + this.oneItem,
    defaultSorted: [{
      dataField: 'name', // if dataField is not match to any column you defined, it will be ignored.
      order: 'asc' // desc or asc
    }],
    columns: [
      {
        dataField: 'name',
        text: "Ονομασία",
        sort: true,
        align: 'center',
        headerAlign: 'center'
      },
      {
        dataField: 'label',
        text: "Εμφανιζόμενη ονομασία",
        sort: true,
        align: 'center',
        headerAlign: 'center'
      },
      {
        dataField: 'email',
        text: Datalanguages["email"],
        sort: true,
        align: 'center',
        headerAlign: 'center'
      },
    ]
  }

  indication() {
    return Datalanguages["Table is Empty"];
  }

  componentDidMount() {
    AuthService.checkUserIsLoggdeIn(this);
    this.getData();
  }

  getData() {
    var _this = this;
    axios.get(_this.state.getDataPath, {
      headers: authHeader(),
    })
      .then(res => {
        _this.state.rows = res.data
        _this.setState(_this.state);
      })
      .catch(error => {
        console.log(error);
        this.setState({
          tableError: error
        });
      })
  }

  createEditModalParams(editMode, row) {
    var _this = this;

    return {
      title: editMode ? _this.editTheTitle : _this.createNewTheTitle,
      openBtn: {
        label: editMode ? Datalanguages["EDIT"] : " + ",
        variant: editMode ? "success" : "primary",
        class: editMode ? "modalBtn" : "createBtn",
      },
      okBtn: {
        label: editMode ? Datalanguages["SAVE"] : Datalanguages["CREATE"],
        variant: editMode ? "success" : "primary"
      },
      body:
        <div>
          <Form.Group controlId="createEditModal_company_name">
            <Form.Label>{"Ονομασία"}</Form.Label>
            <Form.Control as="textarea" rows="1" defaultValue={row && row.name} />
          </Form.Group>
          <Form.Group controlId="createEditModal_company_label">
            <Form.Label>{"Εμφανιζόμενη ονομασία"}</Form.Label>
            <Form.Control as="textarea" rows="1" defaultValue={row && row.label} />
          </Form.Group>
          <Form.Group controlId="createEditModal_company_email">
            <Form.Label>{Datalanguages["email"]}</Form.Label>
            <Form.Control type="text" defaultValue={row && row.email} />
          </Form.Group>
          <Form.Check type="checkbox" label="vininfo"
            checked={_this.state.vininfo}
            onChange={(event) => {
              _this.state.vininfo = !_this.state.vininfo;
              _this.setState(_this.state);
            }}
          />
          <Form.Check type="checkbox" label="TH2E"
            checked={_this.state.TH2E}
            onChange={(event) => {
              _this.state.TH2E = !_this.state.TH2E;
              _this.setState(_this.state);
            }}
          />
          <Form.Check type="checkbox" label="uploadCSV"
            checked={_this.state.uploadCSV}
            onChange={(event) => {
              _this.state.uploadCSV = !_this.state.uploadCSV;
              _this.setState(_this.state);
            }}
          />
        </div>
      ,
      handleOK: function () {
        return editMode === true ? _this.edit(row) : _this.create();
      },
      afterShow: function () {
        if (editMode === true) {
          _this.state.vininfo = row.vininfo;
          _this.state.TH2E = row.TH2E;
          _this.state.uploadCSV = row.uploadCSV;
          _this.setState(_this.state);
        }
        else{
          _this.state.vininfo = 0;
          _this.state.TH2E = 0;
          _this.state.uploadCSV = 0;
          _this.setState(_this.state);
        }
      },
    };
  }

  setInitialValues(row) {
    //console.log(row);
  }

  create() {
    return axios.post(
      this.state.createPath,
      {
        name: $("#createEditModal_company_name").val(),
        label: $("#createEditModal_company_label").val(),
        email: $("#createEditModal_company_email").val(),
        vininfo: this.state.vininfo,
        TH2E: this.state.TH2E,
        uploadCSV: this.state.uploadCSV,
      },
      { headers: authHeader() }
    )
      .then(res => {
        // console.log(res);
        this.state.rows = res.data
        this.setState(this.state);
        return res;
      })
  }

  edit(row) {
    return axios.put(
      this.state.editPath,
      {
        id: row.id,
        name: $("#createEditModal_company_name").val(),
        label: $("#createEditModal_company_label").val(),
        email: $("#createEditModal_company_email").val(),
        vininfo: this.state.vininfo,
        TH2E: this.state.TH2E,
        uploadCSV: this.state.uploadCSV,
      },
      { headers: authHeader() }
    )
      .then(res => {
        // console.log(res);
        this.state.rows = res.data
        this.setState(this.state);
        return res;
      })
  }

  deleteModalParams(row) {
    var _this = this;
    return {
      title: _this.deleteTheTitle,
      openBtn: {
        label: Datalanguages["DELETE"],
        variant: "danger",
        class: "modalBtn"
      },
      okBtn: {
        label: Datalanguages["DELETE"],
        variant: "danger"
      },
      body: "Θελετε να διαγράψετε την εταιρεία " + "\"" + row.name + "\";",
      handleOK: function () {
        return _this.delete(row.id);
      },
      afterRender: function () { },
    };
  }

  delete(ID) {
    return axios.delete(
      this.state.deletePath + "/" + ID,
      { headers: authHeader() }
    )
      .then(res => {
        // console.log(res);
        this.state.rows = res.data
        this.setState(this.state);
        return res;
      })
  }

  expandRow = {
    parentClassName: 'backgroundColorGrey',
    className: 'backgroundColorGrey',
    onlyOneExpanding: true,
    showExpandColumn: false,
    //expandByColumnOnly: true,
    renderer: row => (
      <div>

        {' '}
        <ModalComponent modalParams={this.deleteModalParams(row)} />
        {' '}
        <ModalComponent modalParams={this.createEditModalParams(true, row)} />
      </div>
    )
  }

  render() {
    // console.log(this.state);
    const { SearchBar, ClearSearchButton } = Search;
    return (
      <>
        {/* <div onClick={(e) => this.setState({ error: null })}></div> */}
        {this.state.tableError && <Alert key={'danger'} variant={'danger'} className="tableErrorMsg">
          {this.state.tableError}
        </Alert>}
        <ToolkitProvider
          keyField="id"
          data={this.state.rows}
          columns={this.state.columns}
          bootstrap4
          search
        >
          {
            props => (
              <div>
                <div className="pageTitle">{this.pageTitle}</div>
                {this.hasCreate && <ModalComponent modalParams={this.createEditModalParams()} />}
                {' '}
                <SearchBar
                  placeholder={Datalanguages["Search"]}
                  {...props.searchProps}
                  className={"searchBox"}
                />
                <ClearSearchButton
                  className="btn-secondary searchBoxClearBtn"
                  text='X'
                  {...props.searchProps}
                />
                <BootstrapTable
                  defaultSorted={this.state.defaultSorted}
                  key={this.state.rows}
                  {...props.baseProps}
                  keyField='id'
                  data={this.state.rows}
                  columns={this.state.columns}
                  expandRow={this.hasExpand ? this.expandRow : {}}
                  striped
                  hover
                  bordered={false}
                  noDataIndication={this.indication}
                  bootstrap4
                  pagination={paginationFactory()}
                />
              </div>
            )
          }
        </ToolkitProvider>
      </>
    );
  }
}
