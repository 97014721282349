/* eslint-disable */
import React from 'react';
import configData from "../config/config.js";
import { Form, InputGroup, FormControl } from 'react-bootstrap';
import axios from 'axios';
import ModalComponent from '../components/ModalComponent';
//import TableComponent from './TableComponent';
import $ from 'jquery'

import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
//import { Table } from 'react-bootstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import authHeader from '../services/auth-header';
import AuthService from "../services/auth.service";
import { Alert } from 'react-bootstrap';

import '../App.css';

import Datalanguages from "../languages/el.json"

export default class Producers extends React.Component {

  oneItem = "producer"
  manyItems = "producers"
  createNewTheTitle = Datalanguages["Create new producer"]
  editTheTitle = Datalanguages["Edit producer"]
  deleteTheTitle = Datalanguages["Delete producer"]

  user = AuthService.getCurrentUser();
  //isProduser = this.user && ["ROLE_PRODUCER"].some(substring => this.user.roles.includes(substring));
  hasExpand = true;
  hasDelete = true;
  hasEdit = true;
  hasCreate = true;

  state = {
    rows: [],
    getDataPath: configData.API_URL + 'get' + this.manyItems,
    createPath: configData.API_URL + 'new' + this.oneItem,
    //createPath: configData.API_URL + 'testNew' + this.oneItem,
    editPath: configData.API_URL + 'edit' + this.oneItem,
    deletePath: configData.API_URL + 'delete' + this.oneItem,
    defaultSorted: [{
      dataField: 'id', // if dataField is not match to any column you defined, it will be ignored.
      order: 'desc' // desc or asc
    }],
    columns: [
      {
        dataField: 'code',
        text: Datalanguages["code"],
        sort: true,
        align: 'center',
        headerAlign: 'center'
      },
      {
        dataField: 'user.first_name',
        text: Datalanguages["name"],
        sort: true,
        align: 'center',
        headerAlign: 'center'
      },
      {
        dataField: 'user.last_name',
        text: Datalanguages["last name"],
        sort: true,
        align: 'center',
        headerAlign: 'center'
      },
      {
        dataField: 'afm',
        text: Datalanguages["afm"],
        sort: true,
        align: 'center',
        headerAlign: 'center'
      },
    ]
  }

  indication() {
    return Datalanguages["Table is Empty"];
  }

  componentDidMount() {
    AuthService.checkUserIsLoggdeIn(this);
    this.getData();
  }

  getData() {
    var _this = this;
    axios.get(_this.state.getDataPath, {
      headers: authHeader(),
    })
      .then(res => {
        _this.state.rows = res.data
        _this.setState(_this.state);
      })
      .catch(error => {
        console.log(error);
        this.setState({
          tableError: error
        });
      })
  }

  createEditModalParams(editMode, row) {
    var _this = this;

    return {
      title: editMode ? _this.editTheTitle : _this.createNewTheTitle,
      openBtn: {
        label: editMode ? Datalanguages["EDIT"] : " + ",
        variant: editMode ? "success" : "primary",
        class: editMode ? "modalBtn" : "createBtn",
      },
      okBtn: {
        label: editMode ? Datalanguages["SAVE"] : Datalanguages["CREATE"],
        variant: editMode ? "success" : "primary"
      },
      body:
        <div>
          <Form.Group controlId="createEditModal_producer_code">
            <Form.Label>{Datalanguages["code"]}</Form.Label>
            <Form.Control type="text" defaultValue={editMode === true ? row.code : ""} />
          </Form.Group>
          <br />
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text>{Datalanguages["First and last name"]}</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl id="createEditModal_producer_first_name" type="text" defaultValue={editMode === true ? row.user.first_name : ""} />
            <FormControl id="createEditModal_producer_last_name" type="text" defaultValue={editMode === true ? row.user.last_name : ""} />
          </InputGroup>
          <br />
          <Form.Group controlId="createEditModal_producer_afm">
            <Form.Label>{Datalanguages["afm"]}</Form.Label>
            <Form.Control type="text" defaultValue={editMode === true ? row.afm : ""} />
          </Form.Group>
        </div>,
      handleOK: function () {
        return editMode === true ? _this.edit(row) : _this.create();
      },
      afterRender: function () {
        if (editMode === true) {
          _this.setInitialValues(row);
        }
      },
    };
  }

  setInitialValues(row) {
    //console.log(row); 
  }

  create() {
    return axios.post(
      this.state.createPath,
      {
        first_name: $("#createEditModal_producer_first_name").val(),
        last_name: $("#createEditModal_producer_last_name").val(),
        code: $("#createEditModal_producer_code").val(),
        afm: $("#createEditModal_producer_afm").val()
      },
      { headers: authHeader() }
    ).then(res => {
      console.log(res);
      if (res.status === 200) {
        var tempRows = this.state.rows;
        tempRows.push(res.data);
        this.setState({
          rows: tempRows
        });
      }
      return res;
    });
  }

  edit(row) {
    return axios.put(
      this.state.editPath,
      {
        id: row.id,
        first_name: $("#createEditModal_producer_first_name").val(),
        last_name: $("#createEditModal_producer_last_name").val(),
        code: $("#createEditModal_producer_code").val(),
        afm: $("#createEditModal_producer_afm").val()
      },
      { headers: authHeader() }
    ).then(res => {
      var tempRows = this.state.rows;
      var tempRowIndex = tempRows.findIndex(x => x.id === row.id);
      tempRows[tempRowIndex] = res.data;
      this.setState({
        rows: []
      });
      this.setState({
        rows: tempRows
      });
      return res;
    });
  }

  deleteModalParams(row) {
    var _this = this;
    return {
      title: _this.deleteTheTitle,
      openBtn: {
        label: Datalanguages["DELETE"],
        variant: "danger",
        class: "modalBtn"
      },
      okBtn: {
        label: Datalanguages["DELETE"],
        variant: "danger"
      },
      body: Datalanguages["Are you sure you want to delete producer "] + row.first_name + " " + row.last_name + " ?",
      handleOK: function () {
        return _this.delete(row.id);
      },
      afterRender: function () { },
    };
  }

  delete(ID) {
    return axios.delete(
      this.state.deletePath + "/" + ID,
      { headers: authHeader() }
    ).then(res => {
      console.log(res);
      if (res.status === 200) {
        var tempRows = this.state.rows;
        var pos = tempRows.map(function (e) { return e.id; }).indexOf(ID);
        tempRows.splice(pos, 1);
        this.setState({
          rows: tempRows
        });
      }
      return res;
    })
  }

  expandRow = {
    parentClassName: 'backgroundColorGrey',
    className: 'backgroundColorGrey',
    onlyOneExpanding: true,
    showExpandColumn: false,
    //expandByColumnOnly: true,
    renderer: row => (
      <div>

        {' '}
        <ModalComponent modalParams={this.deleteModalParams(row)} />
        {' '}
        <ModalComponent modalParams={this.createEditModalParams(true, row)} />
      </div>
    )
  }

  render() {
    //console.log(this.state);
    const { SearchBar, ClearSearchButton } = Search;
    return (<>
      {/* <div onClick={(e) => this.setState({ error: null })}></div> */}
      {this.state.tableError && <Alert key={'danger'} variant={'danger'} className="tableErrorMsg">
        {this.state.tableError}
      </Alert>}
      <ToolkitProvider
        keyField="id"
        data={this.state.rows}
        columns={this.state.columns}
        bootstrap4
        search
      >
        {
          props => (
            <div>
              <div className="pageTitle">{Datalanguages["Producers"]}</div>
              {this.hasCreate && <ModalComponent modalParams={this.createEditModalParams()} />}
              {' '}
              <SearchBar
                placeholder={Datalanguages["Search"]}
                {...props.searchProps}
                  className={"searchBox"}
              />
                <ClearSearchButton
                  className="btn-secondary searchBoxClearBtn"
                  text='X'
                  {...props.searchProps}
                />
              <BootstrapTable
                defaultSorted={this.state.defaultSorted}
                key={this.state.rows}
                {...props.baseProps}
                keyField='id'
                data={this.state.rows}
                columns={this.state.columns}
                expandRow={this.hasExpand ? this.expandRow : {}}
                striped
                hover
                bordered={false}
                noDataIndication={this.indication}
                bootstrap4
                pagination={paginationFactory()}
              />
            </div>
          )
        }
      </ToolkitProvider>
    </>
    );
  }
}
