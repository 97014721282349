import { Modal, Button } from 'react-bootstrap';
import React from 'react';
import Datalanguages from "../languages/el.json"
import { Alert } from 'react-bootstrap';


export default class ModalComponent extends React.Component {

  state = {
    show: false,
    InputError: null,
    afterShowError: null,
    loading: false,
  }

  handleShow() {
    // console.log("handleShow");
    //console.log(this.props.modalParams.afterShow);
    if (this.props.modalParams.afterShow) {
      let possiblePromise = this.props.modalParams.afterShow();
      var certainPromise = Promise.resolve(possiblePromise).catch(
        error => {
          //console.log(error);
          this.setState({
            afterShowError: error
          });
        }
      );
    }
    this.setState({
      show: true
    });
  }

  handleClose() {
    this.setState({
      loading: false,
      show: false,
      eInputErrorrror: null,
      afterShowError: null
    });
    this.stopAfterRender = false;
  }

  async handleOK() {

    this.setState({
      loading: true
    });

    this.props.modalParams.handleOK().then(
      res => {
        this.handleClose();
      }
    ).catch(
      error => {
        //console.log(error);
        this.setState({
          InputError: error
        });
      }
    ).finally(
      res => {
        this.setState({
          loading: false
        });
      }
    );
  }

  componentDidUpdate() {
    //console.log(this);
    if (this.props.modalParams.afterRender && !this.stopAfterRender) {
      this.stopAfterRender = true;
      this.props.modalParams.afterRender();
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  render() {
    //console.log("render");
    return (
      <>
        <Button style={this.props.modalParams.openBtn.style} className={this.props.modalParams.openBtn.class} variant={this.props.modalParams.openBtn.variant} size="sm" onClick={(e) => this.handleShow(e)}>
          {this.props.modalParams.openBtn.label}
        </Button>

        <Modal
          backdrop="static"
          className={"zoom"}
          show={this.state.show}
          onHide={(e) => this.handleClose(e)}
          onClick={(e) => this.setState({ InputError: null })}
        >
          <Modal.Header closeButton>
            <Modal.Title>{this.props.modalParams.title}</Modal.Title>
          </Modal.Header>
          {this.state.afterShowError && <Alert key={'danger'} variant={'danger'} style={{ width: "100%" }}>
            {this.state.afterShowError}
          </Alert>}
          <Modal.Body>{this.props.modalParams.body}</Modal.Body>
          <Modal.Footer>
            {this.state.InputError && <Alert key={'danger'} variant={'danger'} style={{ width: "100%" }}>
              {this.state.InputError}
            </Alert>}
            <br />
            <Button disabled={this.state.loading} variant={this.props.modalParams.okBtn.variant} size="sm" onClick={(e) => this.handleOK(e)}>
              {this.state.loading && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
              {this.props.modalParams.okBtn.label}
            </Button>
            <Button variant="secondary" size="sm" onClick={(e) => this.handleClose(e)}>
              {Datalanguages["Close"]}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}